import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button } from 'antd';
import NavbarSales from '../../components/navbar/navbarSales';
import 'antd/dist/antd.css';
import { Tag } from 'antd';
import './DatosSucursales.css';
import { SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const DatosSucursales = () => {
  const [sucursalesData, setSucursalesData] = useState([]);
  const userId = localStorage.getItem('usuario_id');

  const fetchSucursalesData = async () => {
    try {
      const response = await axios.get(`https://api.99envios.app/api/online/vendedor-sucursales/${userId}`);
      setSucursalesData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de las sucursales', error);
    }
  };

  useEffect(() => {
    fetchSucursalesData();
  }, []);

  const columns = [
    {
      title: 'Código Sucursal',
      dataIndex: 'codigoSucursal',
      key: 'codigoSucursal',
      sorter: (a, b) => a.codigoSucursal.localeCompare(b.codigoSucursal),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Usuario',
      dataIndex: 'usuario',
      key: 'usuario',
    },
    {
      title: 'Contraseña',
      dataIndex: 'contrasena',
      key: 'contrasena',
    },
    {
      title: 'Cantidad Envios',
      dataIndex: 'cantidadEnvios',
      key: 'cantidadEnvios',
      sorter: (a, b) => a.cantidadEnvios - b.cantidadEnvios,
    },
  ];

  const data = [
    {
      key: '1',
      codigoSucursal: '25093',
      nombre: 'Tienda Virtual L&D99',
      telefono: '3112879975',
      ciudad: 'BOGOTÁ',
      usuario: 'CLIENTE.ILOVE158',
      contrasena: 'Inter1123160298',
      cantidadEnvios: '',
    },
    {
      key: '2',
      codigoSucursal: '52557',
      nombre: 'Keisly_Store 99',
      telefono: '3024292361',
      ciudad: '',
      usuario: 'CLIENTE.ILOVE1226',
      contrasena: 'Inter37399245',
      cantidadEnvios: '',
    },
    {
      key: '3',
      codigoSucursal: '101297',
      nombre: 'Edgar Nuñez',
      telefono: '3045249859',
      ciudad: 'BOGOTÁ',
      usuario: 'CLIENTE.ILOVE2180',
      contrasena: 'Inter17959399',
      cantidadEnvios: 10,
    },
    {
      key: '4',
      codigoSucursal: '101546',
      nombre: 'EL CASTILLO',
      telefono: '3102171380',
      ciudad: 'BOGOTÁ',
      usuario: 'CLIENTE.ILOVE2235',
      contrasena: 'Inter91296921',
      cantidadEnvios: 1,
    },
    // Agrega más registros según sea necesario
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DatosSucursales');
    XLSX.writeFile(workbook, 'DatosSucursales.xlsx');
  };

  const sucursalesColumns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: (a, b) => a.codigo - b.codigo,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
      filters: [...new Set(sucursalesData.map(item => item.ciudad))].map(ciudad => ({
        text: ciudad,
        value: ciudad,
      })),
      onFilter: (value, record) => record.ciudad.includes(value),
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Total Envíos',
      dataIndex: 'total_preenvios',
      key: 'total_preenvios',
      sorter: (a, b) => a.total_preenvios - b.total_preenvios,
      render: (total) => (
        <Tag color={total === 0 ? 'red' : total > 10 ? 'green' : 'orange'}>
          {total}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <NavbarSales title="Datos Sucursales" />
      <div className="datos-sucursales-container">
        <div className="datos-sucursales-header">
          <h2>Tabla de datos de Sucursales a cargo</h2>
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={handleExportToExcel}
            className="datos-sucursales-export-btn"
          >
            Exportar a Excel
          </Button>
        </div>
        <div className="datos-sucursales-table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
            bordered
            title={() => 'Datos Sucursales'}
            rowKey="codigoSucursal"
          />
        </div>
        <div className="table-containerAD">
          <h2 className="table-title">SUCURSALES POR COMISIONAR</h2>
          <Table 
            columns={sucursalesColumns}
            dataSource={sucursalesData}
            rowKey="codigo"
            pagination={{ 
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} sucursales`
            }}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </>
  );
};

export default DatosSucursales;
