import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import GoogleMapReact from 'google-map-react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import sampleExcelFile from './sample.xlsx';
import ExcelEditor from '../../components/excel';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import './maps.css';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Map = () => {
    const [file, setFile] = useState(null);
    // Updated default location to Bogotá, Colombia
    const [location, setLocation] = useState({ lat: 4.7110, lng: -74.0721 });
    const navigate = useNavigate();
    const now = new Date();
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const currentDate = now.toLocaleDateString('es-ES', options);

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.xlsx, .xls' });

    const downloadExcelFile = () => {
        const link = document.createElement('a');
        link.href = sampleExcelFile;
        link.setAttribute('download', 'sample.xlsx');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <>
            <Navbar title={"Crear pedido masivo"} />
            <div className="containerMap">
                <div className="navbarMap">
                    <div className="navbar-date">{currentDate}</div>
                </div>
                <div className="sidebarMap">
                    <h2>Pedidos</h2>
                    <div className="status-messages">
                        <div className="map-icon">
                            <img src="/Images/mapimg.png" alt="Mapa" />
                        </div>
                        <div className="no-orders">No existen visitas</div>
                        <div className="dropzone" >
                            <ExcelEditor />
                        </div>
                        
                        <p>o</p>
                        <a className="template-link" onClick={downloadExcelFile}>Puedes usar esta plantilla de ejemplo</a>
                    </div>
                </div>
                <div className="map">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyAbErc-9C5rqyrGudtyKlk7LJOfqhbu9c0' }}
                        center={location}
                        defaultZoom={11}
                    >
                        <AnyReactComponent lat={location.lat} lng={location.lng} text={''} />
                    </GoogleMapReact>
                </div>
            </div>
            <BotonWhatsapp />
            <Footer />
        </>
    );
};

export default Map;