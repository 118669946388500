import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './bitacoraCartera.css';

const BitacoraCartera = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(null);

  const updateAvailableBalance = async () => {
    const user_id = localStorage.getItem('usuario_id');
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': token ? `Bearer ${token}` : '',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch balance');

      const data = await response.json();
      setAvailableBalance(data.saldo_disponible);
    } catch (err) {
      console.error('Error al actualizar el saldo disponible:', err);
    }
  };
  
  useEffect(() => {
    const fetchDashboardData = async () => {
      const codigo_sucursal = localStorage.getItem('codigoSucursal');
      try {
        const response = await axios.get(`https://api.99envios.app/public/api/online/listar_estado_cuenta/${codigo_sucursal}`);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
    updateAvailableBalance(); // Fetch available balance when component mounts
  }, []);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  const formatBalance = (balance) => {
    if (balance === null) return 'Cargando...';
    const absBalance = Math.abs(balance);
    const formattedBalance = absBalance.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return balance < 0 ? `-${formattedBalance}` : formattedBalance;
  };
  
  const calculateDescuentosRetiros = (data) => {
    return data.transacciones.total_devoluciones.valor + 
           data.transacciones.total_comision_retiro.valor +
           data.transacciones.total_retiros.valor +
           data.transacciones.total_envios_sin_contrapago.valor;
  };

  return (
    <>
      <div className="dashboard-containerBCU">
        <div className="cards-containerBCU">
          <div className="rowBCU">
            <div className="cardBCU">
              <div className="icon-circle-BCU local-shipping-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px' }}> payments </span>
              </div>
              <h3>Total Pagos</h3>
              <p className="main-number">{formatBalance(dashboardData.Payments)}</p>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU check-circle-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 1 }}> receipt_long </span>
              </div>
              <h3>Descuentos y Retiros</h3>
              <p className="main-number">{formatBalance(calculateDescuentosRetiros(dashboardData))}</p>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU report-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> account_balance_wallet </span>
              </div>
              <h3>Saldo Disponible</h3>
              <p className="main-number">{formatBalance(availableBalance)}</p>
            </div>
          </div>
          <div className="rowBCU">
            <div className="cardBCU">
              <div className="icon-circle-BCU track-changes-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> local_shipping </span>
              </div>
              <h3>Preenvíos</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td><strong>Total Consignado Preenvíos</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.preenvios.total_consignado_preenvios.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.preenvios.total_consignado_preenvios.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Servicio Sin Contrapago</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.preenvios.total_servicio_SinContrapago.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.preenvios.total_servicio_SinContrapago.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Devolución Preenvíos</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.preenvios.total_devolucion_preenvios.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.preenvios.total_devolucion_preenvios.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Servicio Contrapago</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.preenvios.total_servicio_contrapago.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.preenvios.total_servicio_contrapago.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Valor Declarado</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.preenvios.total_valor_declarado.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.preenvios.total_valor_declarado.cantidad}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU track-changes-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> swap_horiz </span>
              </div>
              <h3>Transacciones</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td><strong>Total Devoluciones</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.transacciones.total_devoluciones.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.transacciones.total_devoluciones.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Comisión Retiro</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.transacciones.total_comision_retiro.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.transacciones.total_comision_retiro.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Retiros</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.transacciones.total_retiros.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.transacciones.total_retiros.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Envíos Sin Contrapago</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.transacciones.total_envios_sin_contrapago.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.transacciones.total_envios_sin_contrapago.cantidad}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="cardBCU">
              <div className="icon-circle-BCU track-changes-icon">
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> attach_money </span>
              </div>
              <h3>Recargas</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td><strong>Total Recargas</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.recargas.total_recargas.valor)}</td>
                  </tr>
                  <tr>
                    <td>Cantidad</td>
                    <td className="number-cell-BCU">{dashboardData.recargas.total_recargas.cantidad}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Bono</strong></td>
                  </tr>
                  <tr>
                    <td>Valor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.recargas.total_bono)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="icon-circle-BCU track-changes-icon" style={{ marginTop: "30px" }}>
                <span className="material-icons-sharp" style={{ fontSize: '28px', marginTop: 3 }}> shopping_cart </span>
              </div>
              <h3>Órdenes</h3>
              <table className="card-table-BCU">
                <tbody>
                  <tr>
                    <td>Total Dueño</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.ordenes.total_dueño)}</td>
                  </tr>
                  <tr>
                    <td>Total Vendedor</td>
                    <td className="number-cell-BCU">{formatBalance(dashboardData.ordenes.total_vendedor)}</td>
                  </tr>
                </tbody>
              </table>
            </div>            
          </div>
        </div>
      </div>
    </>
  );
}

export default BitacoraCartera;