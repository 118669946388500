import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './navbarAdminMex.css';

const NavbarAdminMex = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [userId, setUserId] = useState(null);
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchSucursal = async () => {
      const userId = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Asumiendo que necesitas el token para la autorización
  
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener la sucursal');
        }
        const data = await response.json();
        setNombreSucursal(data.nombre_sucursal);
      } catch (error) {
        console.error('Error al obtener la sucursal:', error);
      }
    };
  
    fetchSucursal();
  }, []);
  

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }

    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      const mobileView = window.innerWidth < 768;
      setIsMobile(mobileView);

      // Si la pantalla es mayor a 768px (vista normal), abrimos automáticamente el menú
      if (!mobileView) {
        setMenuOpen(true);
      }
    };

    // Detectar clic fuera de la barra de navegación solo en pantallas móviles
    const handleClickOutside = (event) => {
      if (isMobile && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Añadir el evento de clic fuera y redimensionamiento de la ventana
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    // Limpiar eventos al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="navbar-logo">
          <p style={{ marginLeft: "250px" }}>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, {nombreSucursal}</p>
          <p className="panel-link">99Panel</p>
          <div className="user-avatar">
            <img src="path_to_avatar_image" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`} ref={menuRef}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="navbar-title">
          <img src="/Images/download.png" alt="Download" className="logo" />
        </div>
        <div className="navbar-menu">
          <ul>
            <li>
              <NavLink exact to="/dashboardAdmin" activeClassName="active">
                <span className="material-icons">home</span>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/config-transportadora" activeClassName="active">
                <span className="material-icons">local_shipping</span>Configuración Transportadora
              </NavLink>
            </li>
            <li>
              <NavLink to="/config-negociacion" activeClassName="active">
                <span className="material-icons">handshake</span>Configuración Negociación
              </NavLink>
            </li>
            <li>
              <NavLink to="/actualizar-estados" activeClassName="active">
                <span className="material-icons">update</span>Actualizar Estados
              </NavLink>
            </li>
            <li>
              <NavLink to="/desactivar-guia" activeClassName="active">
                <span className="material-icons">block</span>Desactivar guía
              </NavLink>
            </li>
            <li>
              <NavLink to="/newadmin" activeClassName="active">
                <span className="material-icons">person_add</span>Nuevo admin
              </NavLink>
            </li>
            {/* Bitacora admin */}
            <li>
              <NavLink to="/bitacora-admin" activeClassName="active">
                <span className="material-icons">history</span>Bitácora Admin
              </NavLink>
            </li>
            <li>
              <NavLink to="/nuevo-colaborador" activeClassName="active">
                <span className="material-icons">group_add</span>Nuevo colaborador
              </NavLink>
            </li>
            <li>
              <NavLink to="/crear-bodega" activeClassName="active">
                <span className="material-icons">store</span>Crear Bodega
              </NavLink>
            </li>
            <li>
              <NavLink to="/listar-bodegas" activeClassName="active">
                <span className="material-icons">list_alt</span>Listar Bodegas
              </NavLink>
            </li>
            <li>
              <NavLink to="/sucursalVendedor" activeClassName="active">
                <span className="material-icons">business</span>Sucursal-vendedor
              </NavLink>
            </li>
            <li>
              <NavLink to="/tabla-usuarios" activeClassName="active">
                <span className="material-icons">people</span>Tabla Usuarios
              </NavLink>
            </li>
            <li>
              <NavLink to="/tablaInterrapidisimo" activeClassName="active">
                <span className="material-icons">table_view</span>Tabla Interrapidisimo
              </NavLink>
            </li>
            <li>
              <NavLink to="/listar-cuenta" activeClassName="active">
                <span className="material-icons">account_balance</span>Estado de Cuenta
              </NavLink>
            </li>
            <li>
              <NavLink to="/listar-novedades-admin" activeClassName="active">
                <span className="material-icons">report</span>Listar Novedades
              </NavLink>
            </li>
            <li>
              <NavLink to="/adminpqr" activeClassName="active">
                <span className="material-icons">help</span>PQR
              </NavLink>
            </li>
            <li>
              <NavLink to="/adminpqrfinalizado" activeClassName="active">
                <span className="material-icons">check_circle</span>PQR Finalizados
              </NavLink>
            </li>
            <li>
              <NavLink to="/modal" activeClassName="active">
                <span className="material-icons">open_in_new</span>Modal
              </NavLink>
            </li>          
            <li>
              <NavLink to="/pagos" activeClassName="active">
                <span className="material-icons">payment</span>Pagos
              </NavLink>
            </li>
            <li>
              <NavLink to="/" activeClassName="active">
                <span className="material-icons" onClick={handleLogout}>logout</span>Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavbarAdminMex;
