import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Estilos/irEntregar.css'; // Assume this contains your CSS styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute, faSort } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import Carga from '../../../components/carga';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

function EntregarNow() {

    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    let navigate = useNavigate();

     useEffect(() => {
        const fetchOrders = async () => {
            setIsLoading(true);
            const id_usuario = localStorage.getItem('usuario_id');
            const url =  `https://api.99envios.app/api/reporte-pedidos/EnProceso/${id_usuario}`;
            try {
                const response = await axios.get(url);
                setOrders(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setIsLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const goToOrderDetails = (orderId) => {
        navigate(`/conductor/iniciar-entrega/${orderId}`);
    };

    const optimizeRoute = () => {
        console.log('Optimizing route...');
        // Implementar la lógica de optimización de ruta aquí
    };


    return (
        <div>
            <Navbar title={"Ir a entregar"} />
            <div className="delivery-list">
                <Head title={"Salir a entregar"} />
                {isLoading && <Carga />}
                <div className="header-buttons">
                    {/* <button className="header-button optimize-route" onClick={optimizeRoute}>
                        <FontAwesomeIcon icon={faRoute} /> Optimizar ruta
                    </button> */}
                    <button className="header-button" onClick={() => {/* handle sort action */}}>
                        <FontAwesomeIcon icon={faSort} />
                    </button>
                </div>

                {orders.map((order) => (
                    <div key={order.ID_pedido} className="order-item">
                        <h3>Pedido {order.ID_pedido}</h3>
                        <p>Nombre del cliente: {order.nombre_cliente}</p>
                        {/* <p>Dirección de entrega: {order.direccion_entrega}</p> */}
                        <p>Costo envio: {order.costo_envio}</p>
                        <button className="boton" onClick={() => goToOrderDetails(order.ID_pedido)}>
                            Detalles del pedido
                        </button>
                    </div>
                ))}
            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
}


export default EntregarNow;