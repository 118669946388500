import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';

const { Option } = Select;

const CrearSucursal = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Ajusta la URL según sea necesario
      const response = await axios.post('https://api.99envios.app/api/online/sucursal', values, {
        headers: {
          'Content-Type': 'application/json', // Asegúrate de usar el tipo de contenido correcto
        },
      });

      if (response.status === 201 || response.status === 200) {
        message.success('Sucursal creada exitosamente');
        form.resetFields();
      } else {
        message.error('Hubo un problema al crear la sucursal');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(`Error: ${error.response.data.message || 'Error al crear la sucursal'}`);
      } else {
        message.error('Error de conexión con el servidor');
      }
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavbarAdminMex title="Crear Sucursal" />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{
          maxWidth: '800px',
          margin: '2% auto',
          padding: '20px',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Campos del formulario */}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Nombre y Apellidos"
              name="nombre_apellidos"
              rules={[{ required: true, message: 'Por favor, ingresa el nombre y apellidos' }]}
            >
              <Input placeholder="Ingresa el nombre y apellidos" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Tipo de Identificación"
              name="tipo_identificacion"
              rules={[{ required: true, message: 'Por favor, selecciona el tipo de identificación' }]}
            >
              <Select placeholder="Selecciona el tipo de identificación">
                <Option value="CC">Cédula de Ciudadanía</Option>
                <Option value="CE">Cédula de Extranjería</Option>
                <Option value="NIT">NIT</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Número de Identificación"
              name="num_identificacion"
              rules={[{ required: true, message: 'Por favor, ingresa el número de identificación' }]}
            >
              <Input placeholder="Ingresa el número de identificación" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Dirección"
              name="direccion"
              rules={[{ required: true, message: 'Por favor, ingresa la dirección' }]}
            >
              <Input placeholder="Ingresa la dirección" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Departamento"
              name="departamento"
              rules={[{ required: true, message: 'Por favor, ingresa el departamento' }]}
            >
              <Input placeholder="Ingresa el departamento" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="País"
              name="pais"
              rules={[{ required: true, message: 'Por favor, ingresa el código del país' }]}
            >
              <Input placeholder="Ingresa el código del país" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
          <Form.Item
            name="ciudad_id"
            label="Ciudad"
            rules={[{ required: true, message: 'Seleccione una ciudad' }]}
          >
            <Select
              showSearch
              placeholder="Seleccione una ciudad"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <option value="05045000">APARTADO - ANTIOQUIA</option>
              <option value="05051000">ARBOLETES - ANTIOQUIA</option>
              <option value="05055000">ARGELIA - ANTIOQUIA</option>
              <option value="05059000">ARMENIA - ANTIOQUIA</option>
              <option value="05079000">BARBOSA - ANTIOQUIA</option>
              <option value="05086000">BELMIRA - ANTIOQUIA</option>
              <option value="05088000">BELLO - ANTIOQUIA</option>
              <option value="05091000">BETANIA - ANTIOQUIA</option>
              <option value="05093000">BETULIA - ANTIOQUIA</option>
              <option value="05101000">CIUDAD BOLIVAR - ANTIOQUIA</option>
              <option value="05107000">BRICEÑO - ANTIOQUIA</option>
              <option value="05113000">BURITICA - ANTIOQUIA</option>
              <option value="05120000">CACERES - ANTIOQUIA</option>
              <option value="05125000">CAICEDO - ANTIOQUIA</option>
              <option value="05129000">CALDAS - ANTIOQUIA</option>
              <option value="05134000">CAMPAMENTO - ANTIOQUIA</option>
              <option value="05138000">CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05142000">CARACOLI - ANTIOQUIA</option>
              <option value="05145000">CARAMANTA - ANTIOQUIA</option>
              <option value="05147000">CAREPA - ANTIOQUIA</option>
              <option value="05148000">EL CARMEN DE VIBORAL - ANTIOQUIA</option>
              <option value="05150000">CAROLINA - ANTIOQUIA</option>
              <option value="05154000">CAUCASIA - ANTIOQUIA</option>
              <option value="05172000">CHIGORODO - ANTIOQUIA</option>
              <option value="05190000">CISNEROS - ANTIOQUIA</option>
              <option value="05197000">COCORNA - ANTIOQUIA</option>
              <option value="05206000">CONCEPCION - ANTIOQUIA</option>
              <option value="05209000">CONCORDIA - ANTIOQUIA</option>
              <option value="05212000">COPACABANA - ANTIOQUIA</option>
              <option value="05234000">DABEIBA - ANTIOQUIA</option>
              <option value="05237000">DONMATIAS - ANTIOQUIA</option>
              <option value="05240000">EBEJICO - ANTIOQUIA</option>
              <option value="05250000">EL BAGRE - ANTIOQUIA</option>
              <option value="05264000">ENTRERRIOS - ANTIOQUIA</option>
              <option value="05266000">ENVIGADO - ANTIOQUIA</option>
              <option value="05282000">FREDONIA - ANTIOQUIA</option>
              <option value="05284000">FRONTINO - ANTIOQUIA</option>
              <option value="05306000">GIRALDO - ANTIOQUIA</option>
              <option value="05308000">GIRARDOTA - ANTIOQUIA</option>
              <option value="05310000">GOMEZ PLATA - ANTIOQUIA</option>
              <option value="05313000">GRANADA - ANTIOQUIA</option>
              <option value="05315000">GUADALUPE - ANTIOQUIA</option>
              <option value="05318000">GUARNE - ANTIOQUIA</option>
              <option value="05321000">GUATAPE - ANTIOQUIA</option>
              <option value="05347000">HELICONIA - ANTIOQUIA</option>
              <option value="05353000">HISPANIA - ANTIOQUIA</option>
              <option value="05360000">ITAGUI - ANTIOQUIA</option>
              <option value="05361000">ITUANGO - ANTIOQUIA</option>
              <option value="05364000">JARDIN - ANTIOQUIA</option>
              <option value="05368000">JERICO - ANTIOQUIA</option>
              <option value="05376000">LA CEJA - ANTIOQUIA</option>
              <option value="05380000">LA ESTRELLA - ANTIOQUIA</option>
              <option value="05390000">LA PINTADA - ANTIOQUIA</option>
              <option value="05400000">LA UNION - ANTIOQUIA</option>
              <option value="05411000">LIBORINA - ANTIOQUIA</option>
              <option value="05425000">MACEO - ANTIOQUIA</option>
              <option value="05440000">MARINILLA - ANTIOQUIA</option>
              <option value="05001000">MEDELLIN - ANTIOQUIA</option>
              <option value="05467000">MONTEBELLO - ANTIOQUIA</option>
              <option value="05475000">MURINDO - ANTIOQUIA</option>
              <option value="05480000">MUTATA - ANTIOQUIA</option>
              <option value="05483000">NARIÑO - ANTIOQUIA</option>
              <option value="05490000">NECOCLI - ANTIOQUIA</option>
              <option value="05495000">NECHI - ANTIOQUIA</option>
              <option value="05501000">OLAYA - ANTIOQUIA</option>
              <option value="05541000">PEÑOL - ANTIOQUIA</option>
              <option value="05543000">PEQUE - ANTIOQUIA</option>
              <option value="05576000">PUEBLORRICO - ANTIOQUIA</option>
              <option value="05579000">PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05585000">PUERTO NARE - ANTIOQUIA</option>
              <option value="05591000">PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05604000">REMEDIOS - ANTIOQUIA</option>
              <option value="05607000">RETIRO - ANTIOQUIA</option>
              <option value="05615000">RIONEGRO - ANTIOQUIA</option>
              <option value="05628000">SABANALARGA - ANTIOQUIA</option>
              <option value="05631000">SABANETA - ANTIOQUIA</option>
              <option value="05642000">SALGAR - ANTIOQUIA</option>
              <option value="05647000">SAN ANDRES DE CUERQUIA - ANTIOQUIA</option>
              <option value="05649000">SAN CARLOS - ANTIOQUIA</option>
              <option value="05652000">SAN FRANCISCO - ANTIOQUIA</option>
              <option value="05656000">SAN JERONIMO - ANTIOQUIA</option>
              <option value="05658000">SAN JOSE DE LA MONTAÑA - ANTIOQUIA</option>
              <option value="05659000">SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05660000">SAN LUIS - ANTIOQUIA</option>
              <option value="05664000">SAN PEDRO DE LOS MILAGROS - ANTIOQUIA</option>
              <option value="05665000">SAN PEDRO DE URABA - ANTIOQUIA</option>
              <option value="05667000">SAN RAFAEL - ANTIOQUIA</option>
              <option value="05670000">SAN ROQUE - ANTIOQUIA</option>
              <option value="05674000">SAN VICENTE - ANTIOQUIA</option>
              <option value="05679000">SANTA BARBARA - ANTIOQUIA</option>
              <option value="05686000">SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05690000">SANTO DOMINGO - ANTIOQUIA</option>
              <option value="05697000">EL SANTUARIO - ANTIOQUIA</option>
              <option value="05736000">SEGOVIA - ANTIOQUIA</option>
              <option value="05756000">SONSON - ANTIOQUIA</option>
              <option value="05761000">SOPETRAN - ANTIOQUIA</option>
              <option value="05789000">TAMESIS - ANTIOQUIA</option>
              <option value="05790000">TARAZA - ANTIOQUIA</option>
              <option value="05792000">TARSO - ANTIOQUIA</option>
              <option value="05809000">TITIRIBI - ANTIOQUIA</option>
              <option value="05819000">TOLEDO - ANTIOQUIA</option>
              <option value="05837000">TURBO - ANTIOQUIA</option>
              <option value="05842000">URAMITA - ANTIOQUIA</option>
              <option value="05847000">URRAO - ANTIOQUIA</option>
              <option value="05854000">VALDIVIA - ANTIOQUIA</option>
              <option value="05856000">VALPARAISO - ANTIOQUIA</option>
              <option value="05858000">VEGACHI - ANTIOQUIA</option>
              <option value="05861000">VENECIA - ANTIOQUIA</option>
              <option value="05873000">VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05885000">YALI - ANTIOQUIA</option>
              <option value="05887000">YARUMAL - ANTIOQUIA</option>
              <option value="05890000">YOLOMBO - ANTIOQUIA</option>
              <option value="05893000">YONDO - ANTIOQUIA</option>
              <option value="05895000">ZARAGOZA - ANTIOQUIA</option>
              <option value="81001000">ARAUCA - ARAUCA</option>
              <option value="81065000">ARAUQUITA - ARAUCA</option>
              <option value="81220000">CRAVO NORTE - ARAUCA</option>
              <option value="81300000">FORTUL - ARAUCA</option>
              <option value="81591000">PUERTO RONDON - ARAUCA</option>
              <option value="81736000">SARAVENA - ARAUCA</option>
              <option value="81794000">TAME - ARAUCA</option>
              <option value="08001000">BARRANQUILLA - ATLANTICO</option>
              <option value="08078000">BARANOA - ATLANTICO</option>
              <option value="08137000">CAMPO DE LA CRUZ - ATLANTICO</option>
              <option value="08141000">CANDELARIA - ATLANTICO</option>
              <option value="08296000">GALAPA - ATLANTICO</option>
              <option value="08372000">JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08421000">LURUACO - ATLANTICO</option>
              <option value="08433000">MALAMBO - ATLANTICO</option>
              <option value="08436000">MANATI - ATLANTICO</option>
              <option value="08520000">PALMAR DE VARELA - ATLANTICO</option>
              <option value="08549000">PIOJO - ATLANTICO</option>
              <option value="08558000">POLONUEVO - ATLANTICO</option>
              <option value="08560000">PONEDERA - ATLANTICO</option>
              <option value="08573000">PUERTO COLOMBIA - ATLANTICO</option>
              <option value="08606000">REPELON - ATLANTICO</option>
              <option value="08634000">SABANAGRANDE - ATLANTICO</option>
              <option value="08638000">SABANALARGA - ATLANTICO</option>
              <option value="08675000">SANTA LUCIA - ATLANTICO</option>
              <option value="08685000">SANTO TOMAS - ATLANTICO</option>
              <option value="08758000">SOLEDAD - ATLANTICO</option>
              <option value="08770000">SUAN - ATLANTICO</option>
              <option value="08832000">TUBARA - ATLANTICO</option>
              <option value="08849000">USIACURI - ATLANTICO</option>
              <option value="11001000">BOGOTA D.C.</option>
              <option value="13001000">CARTAGENA - BOLIVAR</option>
              <option value="13006000">ACHI - BOLIVAR</option>
              <option value="13030000">ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13042000">ARENAL - BOLIVAR</option>
              <option value="13052000">ARJONA - BOLIVAR</option>
              <option value="13062000">ARROYOHONDO - BOLIVAR</option>
              <option value="13074000">BARRANCO DE LOBA - BOLIVAR</option>
              <option value="13140000">CALAMAR - BOLIVAR</option>
              <option value="13160000">CANTAGALLO - BOLIVAR</option>
              <option value="13188000">CICUCO - BOLIVAR</option>
              <option value="13212000">CORDOBA - BOLIVAR</option>
              <option value="13222000">CLEMENCIA - BOLIVAR</option>
              <option value="13244000">EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13248000">EL GUAMO - BOLIVAR</option>
              <option value="13268000">EL PEÑON - BOLIVAR</option>
              <option value="13300000">HATILLO DE LOBA - BOLIVAR</option>
              <option value="13430000">MAGANGUE - BOLIVAR</option>
              <option value="13433000">MAHATES - BOLIVAR</option>
              <option value="13440000">MARGARITA - BOLIVAR</option>
              <option value="13442000">MARIA LA BAJA - BOLIVAR</option>
              <option value="13458000">MONTECRISTO - BOLIVAR</option>
              <option value="13468000">MOMPOS - BOLIVAR</option>
              <option value="13473000">MORALES - BOLIVAR</option>
              <option value="13490000">NOROSI - BOLIVAR</option>
              <option value="13549000">PINILLOS - BOLIVAR</option>
              <option value="13580000">REGIDOR - BOLIVAR</option>
              <option value="13600000">RIO VIEJO - BOLIVAR</option>
              <option value="13620000">SAN CRISTOBAL - BOLIVAR</option>
              <option value="13647000">SAN ESTANISLAO - BOLIVAR</option>
              <option value="13650000">SAN FERNANDO - BOLIVAR</option>
              <option value="13654000">SAN JACINTO - BOLIVAR</option>
              <option value="13655000">SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13657000">SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13667000">SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13670000">SAN PABLO - BOLIVAR</option>
              <option value="13673000">SANTA CATALINA - BOLIVAR</option>
              <option value="13683000">SANTA ROSA - BOLIVAR</option>
              <option value="13688000">SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13744000">SIMITI - BOLIVAR</option>
              <option value="13760000">SOPLAVIENTO - BOLIVAR</option>
              <option value="13780000">TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13810000">TIQUISIO - BOLIVAR</option>
              <option value="13836000">TURBACO - BOLIVAR</option>
              <option value="13838000">TURBANA - BOLIVAR</option>
              <option value="13873000">VILLANUEVA - BOLIVAR</option>
              <option value="13894000">ZAMBRANO - BOLIVAR</option>
              <option value="15001000">TUNJA - BOYACA</option>
              <option value="15022000">ALMEIDA - BOYACA</option>
              <option value="15047000">AQUITANIA - BOYACA</option>
              <option value="15051000">ARCABUCO - BOYACA</option>
              <option value="15087000">BELEN - BOYACA</option>
              <option value="15090000">BERBEO - BOYACA</option>
              <option value="15092000">BETEITIVA - BOYACA</option>
              <option value="15097000">BOAVITA - BOYACA</option>
              <option value="15104000">BOYACA - BOYACA</option>                  
              <option value="15106000">BRICEÑO - BOYACA</option>
              <option value="15109000">BUENAVISTA - BOYACA</option>
              <option value="15114000">BUSBANZA - BOYACA</option>
              <option value="15131000">CALDAS - BOYACA</option>
              <option value="15135000">CAMPOHERMOSO - BOYACA</option>
              <option value="15162000">CERINZA - BOYACA</option>
              <option value="15172000">CHINAVITA - BOYACA</option>
              <option value="15176000">CHIQUINQUIRA - BOYACA</option>
              <option value="15180000">CHISCAS - BOYACA</option>
              <option value="15183000">CHITA - BOYACA</option>
              <option value="15185000">CHITARAQUE - BOYACA</option>
              <option value="15187000">CHIVATA - BOYACA</option>
              <option value="15189000">CIENEGA - BOYACA</option>
              <option value="15204000">COMBITA - BOYACA</option>
              <option value="15212000">COPER - BOYACA</option>
              <option value="15215000">CORRALES - BOYACA</option>
              <option value="15218000">COVARACHIA - BOYACA</option>
              <option value="15223000">CUBARA - BOYACA</option>
              <option value="15224000">CUCAITA - BOYACA</option>
              <option value="15226000">CUITIVA - BOYACA</option>
              <option value="15232000">CHIQUIZA - BOYACA</option>
              <option value="15236000">CHIVOR - BOYACA</option>
              <option value="15238000">DUITAMA - BOYACA</option>
              <option value="15244000">EL COCUY - BOYACA</option>
              <option value="15248000">EL ESPINO - BOYACA</option>
              <option value="15272000">FIRAVITOBA - BOYACA</option>
              <option value="15276000">FLORESTA - BOYACA</option>
              <option value="15293000">GACHANTIVA - BOYACA</option>
              <option value="15296000">GAMEZA - BOYACA</option>
              <option value="15299000">GARAGOA - BOYACA</option>
              <option value="15317000">GUACAMAYAS - BOYACA</option>
              <option value="15322000">GUATEQUE - BOYACA</option>
              <option value="15325000">GUAYATA - BOYACA</option>
              <option value="15332000">GUICAN - BOYACA</option>
              <option value="15362000">IZA - BOYACA</option>
              <option value="15367000">JENESANO - BOYACA</option>
              <option value="15368000">JERICO - BOYACA</option>
              <option value="15377000">LABRANZAGRANDE - BOYACA</option>
              <option value="15380000">LA CAPILLA - BOYACA</option>
              <option value="15401000">LA VICTORIA - BOYACA</option>
              <option value="15403000">LA UVITA - BOYACA</option>
              <option value="15407000">VILLA DE LEYVA - BOYACA</option>
              <option value="15425000">MACANAL - BOYACA</option>
              <option value="15442000">MARIPI - BOYACA</option>
              <option value="15455000">MIRAFLORES - BOYACA</option>
              <option value="15464000">MONGUA - BOYACA</option>
              <option value="15466000">MONGUI - BOYACA</option>
              <option value="15469000">MONIQUIRA - BOYACA</option>
              <option value="15476000">MOTAVITA - BOYACA</option>
              <option value="15480000">MUZO - BOYACA</option>
              <option value="15491000">NOBSA - BOYACA</option>
              <option value="15494000">NUEVO COLON - BOYACA</option>
              <option value="15500000">OICATA - BOYACA</option>
              <option value="15507000">OTANCHE - BOYACA</option>
              <option value="15511000">PACHAVITA - BOYACA</option>
              <option value="15514000">PAEZ - BOYACA</option>
              <option value="15516000">PAIPA - BOYACA</option>
              <option value="15518000">PAJARITO - BOYACA</option>
              <option value="15522000">PANQUEBA - BOYACA</option>
              <option value="15531000">PAUNA - BOYACA</option>
              <option value="15533000">PAYA - BOYACA</option>
              <option value="15537000">PAZ DE RIO - BOYACA</option>
              <option value="15542000">PESCA - BOYACA</option>
              <option value="15550000">PISBA - BOYACA</option>
              <option value="15572000">PUERTO BOYACA - BOYACA</option>
              <option value="15580000">QUIPAMA - BOYACA</option>
              <option value="15599000">RAMIRIQUI - BOYACA</option>
              <option value="15600000">RAQUIRA - BOYACA</option>
              <option value="15621000">RONDON - BOYACA</option>
              <option value="15632000">SABOYA - BOYACA</option>
              <option value="15638000">SACHICA - BOYACA</option>
              <option value="15646000">SAMACA - BOYACA</option>
              <option value="15660000">SAN EDUARDO - BOYACA</option>
              <option value="15664000">SAN JOSE DE PARE - BOYACA</option>
              <option value="15667000">SAN LUIS DE GACENO - BOYACA</option>
              <option value="15673000">SAN MATEO - BOYACA</option>
              <option value="15676000">SAN MIGUEL DE SEMA - BOYACA</option>
              <option value="15681000">SAN PABLO DE BORBUR - BOYACA</option>
              <option value="15686000">SANTANA - BOYACA</option>
              <option value="15690000">SANTA MARIA - BOYACA</option>
              <option value="15693000">SANTA ROSA DE VITERBO - BOYACA</option>
              <option value="15696000">SANTA SOFIA - BOYACA</option>
              <option value="15720000">SATIVANORTE - BOYACA</option>
              <option value="15723000">SATIVASUR - BOYACA</option>
              <option value="15740000">SIACHOQUE - BOYACA</option>
              <option value="15753000">SOATA - BOYACA</option>
              <option value="15755000">SOCOTA - BOYACA</option>
              <option value="15757000">SOCHA - BOYACA</option>
              <option value="15759000">SOGAMOSO - BOYACA</option>
              <option value="15761000">SOMONDOCO - BOYACA</option>
              <option value="15762000">SORA - BOYACA</option>
              <option value="15763000">SOTAQUIRA - BOYACA</option>
              <option value="15764000">SORACA - BOYACA</option>
              <option value="15774000">SUSACON - BOYACA</option>
              <option value="15776000">SUTAMARCHAN - BOYACA</option>
              <option value="15778000">SUTATENZA - BOYACA</option>
              <option value="15790000">TASCO - BOYACA</option>
              <option value="15798000">TENZA - BOYACA</option>
              <option value="15804000">TIBANA - BOYACA</option>
              <option value="15806000">TIBASOSA - BOYACA</option>
              <option value="15808000">TINJACA - BOYACA</option>
              <option value="15810000">TIPACOQUE - BOYACA</option>
              <option value="15814000">TOCA - BOYACA</option>
              <option value="15816000">TOGUI - BOYACA</option>
              <option value="15820000">TOPAGA - BOYACA</option>
              <option value="15822000">TOTA - BOYACA</option>
              <option value="15832000">TUNUNGUA - BOYACA</option>
              <option value="15835000">TURMEQUE - BOYACA</option>
              <option value="15837000">TUTA - BOYACA</option>
              <option value="15839000">TUTAZA - BOYACA</option>
              <option value="15842000">UMBITA - BOYACA</option>
              <option value="15861000">VENTAQUEMADA - BOYACA</option>
              <option value="15879000">VIRACACHA - BOYACA</option>
              <option value="15897000">ZETAQUIRA - BOYACA</option>
              <option value="17001000">MANIZALES - CALDAS</option>
              <option value="17013000">AGUADAS - CALDAS</option>
              <option value="17042000">ANSERMA - CALDAS</option>
              <option value="17050000">ARANZAZU - CALDAS</option>
              <option value="17088000">BELALCAZAR - CALDAS</option>
              <option value="17174000">CHINCHINA - CALDAS</option>
              <option value="17272000">FILADELFIA - CALDAS</option>
              <option value="17380000">LA DORADA - CALDAS</option>
              <option value="17388000">LA MERCED - CALDAS</option>
              <option value="17433000">MANZANARES - CALDAS</option>
              <option value="17442000">MARMATO - CALDAS</option>
              <option value="17444000">MARQUETALIA - CALDAS</option>
              <option value="17446000">MARULANDA - CALDAS</option>
              <option value="17486000">NEIRA - CALDAS</option>
              <option value="17495000">NORCASIA - CALDAS</option>
              <option value="17513000">PACORA - CALDAS</option>
              <option value="17524000">PALESTINA - CALDAS</option>
              <option value="17541000">PENSILVANIA - CALDAS</option>
              <option value="17614000">RIOSUCIO - CALDAS</option>
              <option value="17616000">RISARALDA - CALDAS</option>
              <option value="17653000">SALAMINA - CALDAS</option>
              <option value="17662000">SAMANA - CALDAS</option>
              <option value="17665000">SAN JOSE - CALDAS</option>
              <option value="17777000">SUPIA - CALDAS</option>
              <option value="17867000">VICTORIA - CALDAS</option>
              <option value="17873000">VILLAMARIA - CALDAS</option>
              <option value="17877000">VITERBO - CALDAS</option>
              <option value="18001000">FLORENCIA - CAQUETA</option>
              <option value="18029000">ALBANIA - CAQUETA</option>
              <option value="18094000">BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18150000">CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18205000">CURILLO - CAQUETA</option>
              <option value="18247000">EL DONCELLO - CAQUETA</option>
              <option value="18256000">EL PAUJIL - CAQUETA</option>
              <option value="18410000">LA MONTAÑITA - CAQUETA</option>
              <option value="18460000">MILAN - CAQUETA</option>
              <option value="18479000">MORELIA - CAQUETA</option>
              <option value="18592000">PUERTO RICO - CAQUETA</option>
              <option value="18610000">SAN JOSE DEL FRAGUA - CAQUETA</option>
              <option value="18753000">SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18756000">SOLANO - CAQUETA</option>
              <option value="18785000">SOLITA - CAQUETA</option>
              <option value="18860000">VALPARAISO - CAQUETA</option>
              <option value="85001000">YOPAL - CASANARE</option>
              <option value="85010000">AGUAZUL - CASANARE</option>
              <option value="85015000">CHAMEZA - CASANARE</option>
              <option value="85125000">HATO COROZAL - CASANARE</option>
              <option value="85136000">LA SALINA - CASANARE</option>
              <option value="85139000">MANI - CASANARE</option>
              <option value="85162000">MONTERREY - CASANARE</option>
              <option value="85225000">NUNCHIA - CASANARE</option>
              <option value="85230000">OROCUE - CASANARE</option>
              <option value="85250000">PAZ DE ARIPORO - CASANARE</option>
              <option value="85263000">PORE - CASANARE</option>
              <option value="85279000">RECETOR - CASANARE</option>
              <option value="85300000">SABANALARGA - CASANARE</option>
              <option value="85315000">SACAMA - CASANARE</option>
              <option value="85325000">SAN LUIS DE PALENQUE - CASANARE</option>
              <option value="85400000">TAMARA - CASANARE</option>
              <option value="85410000">TAURAMENA - CASANARE</option>
              <option value="85430000">TRINIDAD - CASANARE</option>
              <option value="85440000">VILLANUEVA - CASANARE</option>
              <option value="19001000">POPAYAN - CAUCA</option>
              <option value="19022000">ALMAGUER - CAUCA</option>
              <option value="19050000">ARGELIA - CAUCA</option>
              <option value="19075000">BALBOA - CAUCA</option>
              <option value="19100000">BOLIVAR - CAUCA</option>
              <option value="19110000">BUENOS AIRES - CAUCA</option>
              <option value="19130000">CAJIBIO - CAUCA</option>
              <option value="19137000">CALDONO - CAUCA</option>
              <option value="19142000">CALOTO - CAUCA</option>
              <option value="19212000">CORINTO - CAUCA</option>
              <option value="19256000">EL TAMBO - CAUCA</option>
              <option value="19290000">FLORENCIA - CAUCA</option>
              <option value="19300000">GUACHENE - CAUCA</option>
              <option value="19318000">GUAPI - CAUCA</option>
              <option value="19355000">INZA - CAUCA</option>
              <option value="19364000">JAMBALO - CAUCA</option>
              <option value="19392000">LA SIERRA - CAUCA</option>
              <option value="19397000">LA VEGA - CAUCA</option>
              <option value="19418000">LOPEZ - CAUCA</option>
              <option value="19450000">MERCADERES - CAUCA</option>
              <option value="19455000">MIRANDA - CAUCA</option>
              <option value="19473000">MORALES - CAUCA</option>
              <option value="19513000">PADILLA - CAUCA</option>
              <option value="19517000">PAEZ - CAUCA</option>
              <option value="19532000">PATIA - CAUCA</option>
              <option value="19533000">PIAMONTE - CAUCA</option>
              <option value="19548000">PIENDAMO - CAUCA</option>
              <option value="19573000">PUERTO TEJADA - CAUCA</option>
              <option value="19585000">PURACE - CAUCA</option>
              <option value="19622000">ROSAS - CAUCA</option>
              <option value="19693000">SAN SEBASTIAN - CAUCA</option>
              <option value="19698000">SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19701000">SANTA ROSA - CAUCA</option>
              <option value="19743000">SILVIA - CAUCA</option>
              <option value="19760000">SOTARA - CAUCA</option>
              <option value="19780000">SUAREZ - CAUCA</option>
              <option value="19785000">SUCRE - CAUCA</option>
              <option value="19807000">TIMBIO - CAUCA</option>
              <option value="19809000">TIMBIQUI - CAUCA</option>
              <option value="19821000">TORIBIO - CAUCA</option>
              <option value="19824000">TOTORO - CAUCA</option>
              <option value="19845000">VILLA RICA - CAUCA</option>
              <option value="20001000">VALLEDUPAR - CESAR</option>
              <option value="20011000">AGUACHICA - CESAR</option>
              <option value="20013000">AGUSTIN CODAZZI - CESAR</option>
              <option value="20032000">ASTREA - CESAR</option>
              <option value="20045000">BECERRIL - CESAR</option>
              <option value="20060000">BOSCONIA - CESAR</option>
              <option value="20175000">CHIMICHAGUA - CESAR</option>
              <option value="20178000">CHIRIGUANA - CESAR</option>
              <option value="20228000">CURUMANI - CESAR</option>
              <option value="20238000">EL COPEY - CESAR</option>
              <option value="20250000">EL PASO - CESAR</option>
              <option value="20295000">GAMARRA - CESAR</option>
              <option value="20310000">GONZALEZ - CESAR</option>
              <option value="20383000">LA GLORIA - CESAR</option>
              <option value="20400000">LA JAGUA DE IBIRICO - CESAR</option>
              <option value="20443000">MANAURE - CESAR</option>
              <option value="20517000">PAILITAS - CESAR</option>
              <option value="20550000">PELAYA - CESAR</option>
              <option value="20570000">PUEBLO BELLO - CESAR</option>
              <option value="20614000">RIO DE ORO - CESAR</option>
              <option value="20621000">LA PAZ - CESAR</option>
              <option value="20710000">SAN ALBERTO - CESAR</option>
              <option value="20750000">SAN DIEGO - CESAR</option>
              <option value="20770000">SAN MARTIN - CESAR</option>
              <option value="20787000">TAMALAMEQUE - CESAR</option>
              <option value="27001000">QUIBDO - CHOCO</option>
              <option value="27006000">ACANDI - CHOCO</option>
              <option value="27025000">ALTO BAUDO - CHOCO</option>
              <option value="27050000">ATRATO - CHOCO</option>
              <option value="27073000">BAGADO - CHOCO</option>
              <option value="27075000">BAHIA SOLANO - CHOCO</option>
              <option value="27077000">BAJO BAUDO - CHOCO</option>
              <option value="27099000">BOJAYA - CHOCO</option>
              <option value="27135000">EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27150000">CARMEN DEL DARIEN - CHOCO</option>
              <option value="27160000">CERTEGUI - CHOCO</option>
              <option value="27205000">CONDOTO - CHOCO</option>
              <option value="27245000">EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27250000">EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27361000">ISTMINA - CHOCO</option>
              <option value="27372000">JURADO - CHOCO</option>
              <option value="27413000">LLORO - CHOCO</option>
              <option value="27425000">MEDIO ATRATO - CHOCO</option>
              <option value="27430000">MEDIO BAUDO - CHOCO</option>
              <option value="27450000">MEDIO SAN JUAN - CHOCO</option>
              <option value="27491000">NOVITA - CHOCO</option>
              <option value="27495000">NUQUI - CHOCO</option>
              <option value="27580000">RIO IRO - CHOCO</option>
              <option value="27600000">RIO QUITO - CHOCO</option>
              <option value="27615000">RIOSUCIO - CHOCO</option>
              <option value="27660000">SAN JOSE DEL PALMAR - CHOCO</option>
              <option value="27745000">SIPI - CHOCO</option>
              <option value="27787000">TADO - CHOCO</option>
              <option value="27800000">UNGUIA - CHOCO</option>
              <option value="27810000">UNION PANAMERICANA - CHOCO</option>
              <option value="23001000">MONTERIA - CORDOBA</option>
              <option value="23068000">AYAPEL - CORDOBA</option>
              <option value="23079000">BUENAVISTA - CORDOBA</option>
              <option value="23090000">CANALETE - CORDOBA</option>
              <option value="23162000">CERETE - CORDOBA</option>
              <option value="23168000">CHIMA - CORDOBA</option>
              <option value="23182000">CHINU - CORDOBA</option>
              <option value="23189000">CIENAGA DE ORO - CORDOBA</option>
              <option value="23300000">COTORRA - CORDOBA</option>
              <option value="23350000">LA APARTADA - CORDOBA</option>
              <option value="23417000">LORICA - CORDOBA</option>
              <option value="23419000">LOS CORDOBAS - CORDOBA</option>
              <option value="23464000">MOMIL - CORDOBA</option>
              <option value="23466000">MONTELIBANO - CORDOBA</option>
              <option value="23500000">MOÑITOS - CORDOBA</option>
              <option value="23555000">PLANETA RICA - CORDOBA</option>
              <option value="23570000">PUEBLO NUEVO - CORDOBA</option>
              <option value="23574000">PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23580000">PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23586000">PURISIMA - CORDOBA</option>
              <option value="23660000">SAHAGUN - CORDOBA</option>
              <option value="23670000">SAN ANDRES SOTAVENTO - CORDOBA</option>
              <option value="23672000">SAN ANTERO - CORDOBA</option>
              <option value="23675000">SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23678000">SAN CARLOS - CORDOBA</option>
              <option value="23682000">SAN JOSE DE URE - CORDOBA</option>
              <option value="23686000">SAN PELAYO - CORDOBA</option>
              <option value="23807000">TIERRALTA - CORDOBA</option>
              <option value="23815000">TUCHIN - CORDOBA</option>
              <option value="23855000">VALENCIA - CORDOBA</option>
              <option value="25001000">AGUA DE DIOS - CUNDINAMARCA</option>
              <option value="25019000">ALBAN - CUNDINAMARCA</option>
              <option value="25035000">ANAPOIMA - CUNDINAMARCA</option>
              <option value="25040000">ANOLAIMA - CUNDINAMARCA</option>
              <option value="25053000">ARBELAEZ - CUNDINAMARCA</option>
              <option value="25086000">BELTRAN - CUNDINAMARCA</option>
              <option value="25095000">BITUIMA - CUNDINAMARCA</option>
              <option value="25099000">BOJACA - CUNDINAMARCA</option>
              <option value="25120000">CABRERA - CUNDINAMARCA</option>
              <option value="25123000">CACHIPAY - CUNDINAMARCA</option>
              <option value="25126000">CAJICA - CUNDINAMARCA</option>
              <option value="25148000">CAPARRAPI - CUNDINAMARCA</option>
              <option value="25151000">CAQUEZA - CUNDINAMARCA</option>
              <option value="25154000">CARMEN DE CARUPA - CUNDINAMARCA</option>
              <option value="25168000">CHAGUANI - CUNDINAMARCA</option>
              <option value="25175000">CHIA - CUNDINAMARCA</option>
              <option value="25178000">CHIPAQUE - CUNDINAMARCA</option>
              <option value="25181000">CHOACHI - CUNDINAMARCA</option>
              <option value="25183000">CHOCONTA - CUNDINAMARCA</option>
              <option value="25200000">COGUA - CUNDINAMARCA</option>
              <option value="25214000">COTA - CUNDINAMARCA</option>
              <option value="25224000">CUCUNUBA - CUNDINAMARCA</option>
              <option value="25245000">EL COLEGIO - CUNDINAMARCA</option>
              <option value="25258000">EL PEÑON - CUNDINAMARCA</option>
              <option value="25260000">EL ROSAL - CUNDINAMARCA</option>
              <option value="25269000">FACATATIVA - CUNDINAMARCA</option>
              <option value="25279000">FOMEQUE - CUNDINAMARCA</option>
              <option value="25281000">FOSCA - CUNDINAMARCA</option>
              <option value="25286000">FUNZA - CUNDINAMARCA</option>
              <option value="25288000">FUQUENE - CUNDINAMARCA</option>
              <option value="25290000">FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25293000">GACHALA - CUNDINAMARCA</option>
              <option value="25295000">GACHANCIPA - CUNDINAMARCA</option>
              <option value="25297000">GACHETA - CUNDINAMARCA</option>
              <option value="25299000">GAMA - CUNDINAMARCA</option>
              <option value="25307000">GIRARDOT - CUNDINAMARCA</option>
              <option value="25312000">GRANADA - CUNDINAMARCA</option>
              <option value="25317000">GUACHETA - CUNDINAMARCA</option>
              <option value="25320000">GUADUAS - CUNDINAMARCA</option>
              <option value="25322000">GUASCA - CUNDINAMARCA</option>
              <option value="25324000">GUATAQUI - CUNDINAMARCA</option>
              <option value="25326000">GUATAVITA - CUNDINAMARCA</option>
              <option value="25328000">GUAYABAL DE SIQUIMA - CUNDINAMARCA</option>
              <option value="25335000">GUAYABETAL - CUNDINAMARCA</option>
              <option value="25339000">GUTIERREZ - CUNDINAMARCA</option>
              <option value="25368000">JERUSALEN - CUNDINAMARCA</option>
              <option value="25372000">JUNIN - CUNDINAMARCA</option>
              <option value="25377000">LA CALERA - CUNDINAMARCA</option>
              <option value="25386000">LA MESA - CUNDINAMARCA</option>
              <option value="25394000">LA PALMA - CUNDINAMARCA</option>
              <option value="25398000">LA PEÑA - CUNDINAMARCA</option>
              <option value="25402000">LA VEGA - CUNDINAMARCA</option>
              <option value="25407000">LENGUAZAQUE - CUNDINAMARCA</option>
              <option value="25426000">MACHETA - CUNDINAMARCA</option>
              <option value="25430000">MADRID - CUNDINAMARCA</option>
              <option value="25436000">MANTA - CUNDINAMARCA</option>
              <option value="25438000">MEDINA - CUNDINAMARCA</option>
              <option value="25473000">MOSQUERA - CUNDINAMARCA</option>
              <option value="25483000">NARIÑO - CUNDINAMARCA</option>
              <option value="25486000">NEMOCON - CUNDINAMARCA</option>
              <option value="25488000">NILO - CUNDINAMARCA</option>
              <option value="25489000">NIMAIMA - CUNDINAMARCA</option>
              <option value="25491000">NOCAIMA - CUNDINAMARCA</option>
              <option value="25506000">VENECIA - CUNDINAMARCA</option>
              <option value="25513000">PACHO - CUNDINAMARCA</option>
              <option value="25518000">PAIME - CUNDINAMARCA</option>
              <option value="25524000">PANDI - CUNDINAMARCA</option>
              <option value="25530000">PARATEBUENO - CUNDINAMARCA</option>
              <option value="25535000">PASCA - CUNDINAMARCA</option>
              <option value="25572000">PUERTO SALGAR - CUNDINAMARCA</option>
              <option value="25580000">PULI - CUNDINAMARCA</option>
              <option value="25592000">QUEBRADANEGRA - CUNDINAMARCA</option>
              <option value="25594000">QUETAME - CUNDINAMARCA</option>
              <option value="25596000">QUIPILE - CUNDINAMARCA</option>
              <option value="25599000">APULO - CUNDINAMARCA</option>
              <option value="25612000">RICAURTE - CUNDINAMARCA</option>
              <option value="25645000">SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25649000">SAN BERNARDO - CUNDINAMARCA</option>
              <option value="25653000">SAN CAYETANO - CUNDINAMARCA</option>
              <option value="25658000">SAN FRANCISCO - CUNDINAMARCA</option>
              <option value="25662000">SAN JUAN DE RIO SECO - CUNDINAMARCA</option>
              <option value="25718000">SASAIMA - CUNDINAMARCA</option>
              <option value="25736000">SESQUILE - CUNDINAMARCA</option>
              <option value="25740000">SIBATE - CUNDINAMARCA</option>
              <option value="25743000">SILVANIA - CUNDINAMARCA</option>
              <option value="25745000">SIMIJACA - CUNDINAMARCA</option>
              <option value="25754000">SOACHA - CUNDINAMARCA</option>
              <option value="25758000">SOPO - CUNDINAMARCA</option>
              <option value="25769000">SUBACHOQUE - CUNDINAMARCA</option>
              <option value="25772000">SUESCA - CUNDINAMARCA</option>
              <option value="25777000">SUPATA - CUNDINAMARCA</option>
              <option value="25779000">SUSA - CUNDINAMARCA</option>
              <option value="25781000">SUTATAUSA - CUNDINAMARCA</option>
              <option value="25785000">TABIO - CUNDINAMARCA</option>
              <option value="25793000">TAUSA - CUNDINAMARCA</option>
              <option value="25797000">TENA - CUNDINAMARCA</option>
              <option value="25799000">TENJO - CUNDINAMARCA</option>
              <option value="25805000">TIBACUY - CUNDINAMARCA</option>
              <option value="25807000">TIBIRITA - CUNDINAMARCA</option>
              <option value="25815000">TOCAIMA - CUNDINAMARCA</option>
              <option value="25817000">TOCANCIPA - CUNDINAMARCA</option>
              <option value="25823000">TOPAIPI - CUNDINAMARCA</option>
              <option value="25839000">UBALA - CUNDINAMARCA</option>
              <option value="25841000">UBAQUE - CUNDINAMARCA</option>
              <option value="25843000">VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25845000">UNE - CUNDINAMARCA</option>
              <option value="25851000">UTICA - CUNDINAMARCA</option>
              <option value="25862000">VERGARA - CUNDINAMARCA</option>
              <option value="25867000">VIANI - CUNDINAMARCA</option>
              <option value="25871000">VILLAGOMEZ - CUNDINAMARCA</option>
              <option value="25873000">VILLAPINZON - CUNDINAMARCA</option>
              <option value="25875000">VILLETA - CUNDINAMARCA</option>
              <option value="25878000">VIOTA - CUNDINAMARCA</option>
              <option value="25885000">YACOPI - CUNDINAMARCA</option>
              <option value="25898000">ZIPACON - CUNDINAMARCA</option>
              <option value="25899000">ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="94001000">INIRIDA - GUAINIA</option>
              <option value="94343000">BARRANCO MINAS - GUAINIA</option>
              <option value="94663000">MAPIRIPANA - GUAINIA</option>
              <option value="94883000">SAN FELIPE - GUAINIA</option>
              <option value="94884000">PUERTO COLOMBIA - GUAINIA</option>
              <option value="94885000">LA GUADALUPE - GUAINIA</option>
              <option value="94886000">CACAHUAL - GUAINIA</option>
              <option value="94887000">PANA PANA - GUAINIA</option>
              <option value="94888000">MORICHAL - GUAINIA</option>
              <option value="95001000">SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95015000">CALAMAR - GUAVIARE</option>
              <option value="95025000">EL RETORNO - GUAVIARE</option>
              <option value="95200000">MIRAFLORES - GUAVIARE</option>
              <option value="41001000">NEIVA - HUILA</option>
              <option value="41006000">ACEVEDO - HUILA</option>
              <option value="41013000">AGRADO - HUILA</option>
              <option value="41016000">AIPE - HUILA</option>
              <option value="41020000">ALGECIRAS - HUILA</option>
              <option value="41026000">ALTAMIRA - HUILA</option>
              <option value="41078000">BARAYA - HUILA</option>
              <option value="41132000">CAMPOALEGRE - HUILA</option>
              <option value="41206000">COLOMBIA - HUILA</option>
              <option value="41244000">ELIAS - HUILA</option>
              <option value="41298000">GARZON - HUILA</option>
              <option value="41306000">GIGANTE - HUILA</option>
              <option value="41319000">GUADALUPE - HUILA</option>
              <option value="41349000">HOBO - HUILA</option>
              <option value="41357000">IQUIRA - HUILA</option>
              <option value="41359000">ISNOS - HUILA</option>
              <option value="41378000">LA ARGENTINA - HUILA</option>
              <option value="41396000">LA PLATA - HUILA</option>
              <option value="41483000">NATAGA - HUILA</option>
              <option value="41503000">OPORAPA - HUILA</option>
              <option value="41518000">PAICOL - HUILA</option>
              <option value="41524000">PALERMO - HUILA</option>
              <option value="41530000">PALESTINA - HUILA</option>
              <option value="41548000">PITAL - HUILA</option>
              <option value="41551000">PITALITO - HUILA</option>
              <option value="41615000">RIVERA - HUILA</option>
              <option value="41660000">SALADOBLANCO - HUILA</option>
              <option value="41668000">SAN AGUSTIN - HUILA</option>
              <option value="41676000">SANTA MARIA - HUILA</option>
              <option value="41770000">SUAZA - HUILA</option>
              <option value="41791000">TARQUI - HUILA</option>
              <option value="41797000">TESALIA - HUILA</option>
              <option value="41799000">TELLO - HUILA</option>
              <option value="41801000">TERUEL - HUILA</option>
              <option value="41807000">TIMANA - HUILA</option>
              <option value="41872000">VILLAVIEJA - HUILA</option>
              <option value="41885000">YAGUARA - HUILA</option>
              <option value="44001000">RIOHACHA - LA GUAJIRA</option>
              <option value="44035000">ALBANIA - LA GUAJIRA</option>
              <option value="44078000">BARRANCAS - LA GUAJIRA</option>
              <option value="44090000">DIBULLA - LA GUAJIRA</option>
              <option value="44098000">DISTRACCION - LA GUAJIRA</option>
              <option value="44110000">EL MOLINO - LA GUAJIRA</option>
              <option value="44279000">FONSECA - LA GUAJIRA</option>
              <option value="44378000">HATONUEVO - LA GUAJIRA</option>
              <option value="44420000">LA JAGUA DEL PILAR - LA GUAJIRA</option>
              <option value="44430000">MAICAO - LA GUAJIRA</option>
              <option value="44560000">MANAURE - LA GUAJIRA</option>
              <option value="44650000">SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44847000">URIBIA - LA GUAJIRA</option>
              <option value="44855000">URUMITA - LA GUAJIRA</option>
              <option value="44874000">VILLANUEVA - LA GUAJIRA</option>
              <option value="47001000">SANTA MARTA - MAGDALENA</option>
              <option value="47030000">ALGARROBO - MAGDALENA</option>
              <option value="47053000">ARACATACA - MAGDALENA</option>
              <option value="47058000">ARIGUANI - MAGDALENA</option>
              <option value="47161000">CERRO SAN ANTONIO - MAGDALENA</option>
              <option value="47170000">CHIVOLO - MAGDALENA</option>
              <option value="47189000">CIENAGA - MAGDALENA</option>
              <option value="47205000">CONCORDIA - MAGDALENA</option>
              <option value="47245000">EL BANCO - MAGDALENA</option>
              <option value="47258000">EL PIÑON - MAGDALENA</option>
              <option value="47268000">EL RETEN - MAGDALENA</option>
              <option value="47288000">FUNDACION - MAGDALENA</option>
              <option value="47318000">GUAMAL - MAGDALENA</option>
              <option value="47460000">NUEVA GRANADA - MAGDALENA</option>
              <option value="47541000">PEDRAZA - MAGDALENA</option>
              <option value="47545000">PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47551000">PIVIJAY - MAGDALENA</option>
              <option value="47555000">PLATO - MAGDALENA</option>
              <option value="47570000">PUEBLOVIEJO - MAGDALENA</option>
              <option value="47605000">REMOLINO - MAGDALENA</option>
              <option value="47660000">SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47675000">SALAMINA - MAGDALENA</option>
              <option value="47692000">SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47703000">SAN ZENON - MAGDALENA</option>
              <option value="47707000">SANTA ANA - MAGDALENA</option>
              <option value="47720000">SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47745000">SITIONUEVO - MAGDALENA</option>
              <option value="47798000">TENERIFE - MAGDALENA</option>
              <option value="47960000">ZAPAYAN - MAGDALENA</option>
              <option value="47980000">ZONA BANANERA - MAGDALENA</option>
              <option value="50001000">VILLAVICENCIO - META</option>
              <option value="50006000">ACACIAS - META</option>
              <option value="50110000">BARRANCA DE UPIA - META</option>
              <option value="50124000">CABUYARO - META</option>
              <option value="50150000">CASTILLA LA NUEVA - META</option>
              <option value="50223000">CUBARRAL - META</option>
              <option value="50226000">CUMARAL - META</option>
              <option value="50245000">EL CALVARIO - META</option>
              <option value="50251000">EL CASTILLO - META</option>
              <option value="50270000">EL DORADO - META</option>
              <option value="50287000">FUENTE DE ORO - META</option>
              <option value="50313000">GRANADA - META</option>
              <option value="50318000">GUAMAL - META</option>
              <option value="50325000">MAPIRIPAN - META</option>
              <option value="50330000">MESETAS - META</option>
              <option value="50350000">LA MACARENA - META</option>
              <option value="50370000">URIBE - META</option>
              <option value="50400000">LEJANIAS - META</option>
              <option value="50450000">PUERTO CONCORDIA - META</option>
              <option value="50568000">PUERTO GAITAN - META</option>
              <option value="50573000">PUERTO LOPEZ - META</option>
              <option value="50577000">PUERTO LLERAS - META</option>
              <option value="50590000">PUERTO RICO - META</option>
              <option value="50606000">RESTREPO - META</option>
              <option value="50680000">SAN CARLOS DE GUAROA - META</option>
              <option value="50683000">SAN JUAN DE ARAMA - META</option>
              <option value="50686000">SAN JUANITO - META</option>
              <option value="50689000">SAN MARTIN - META</option>
              <option value="50711000">VISTAHERMOSA - META</option>
              <option value="52001000">PASTO - NARIÑO</option>
              <option value="52019000">ALBAN - NARIÑO</option>
              <option value="52022000">ALDANA - NARIÑO</option>
              <option value="52036000">ANCUYA - NARIÑO</option>
              <option value="52051000">ARBOLEDA - NARIÑO</option>
              <option value="52079000">BARBACOAS - NARIÑO</option>
              <option value="52083000">BELEN - NARIÑO</option>
              <option value="52110000">BUESACO - NARIÑO</option>
              <option value="52203000">COLON - NARIÑO</option>
              <option value="52207000">CONSACA - NARIÑO</option>
              <option value="52210000">CONTADERO - NARIÑO</option>
              <option value="52215000">CORDOBA - NARIÑO</option>
              <option value="52224000">CUASPUD - NARIÑO</option>
              <option value="52227000">CUMBAL - NARIÑO</option>
              <option value="52233000">CUMBITARA - NARIÑO</option>
              <option value="52240000">CHACHAGUI - NARIÑO</option>
              <option value="52250000">EL CHARCO - NARIÑO</option>
              <option value="52254000">EL PEÑOL - NARIÑO</option>
              <option value="52256000">EL ROSARIO - NARIÑO</option>
              <option value="52258000">EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52260000">EL TAMBO - NARIÑO</option>
              <option value="52287000">FUNES - NARIÑO</option>
              <option value="52317000">GUACHUCAL - NARIÑO</option>
              <option value="52320000">GUAITARILLA - NARIÑO</option>
              <option value="52323000">GUALMATAN - NARIÑO</option>
              <option value="52352000">ILES - NARIÑO</option>
              <option value="52354000">IMUES - NARIÑO</option>
              <option value="52356000">IPIALES - NARIÑO</option>
              <option value="52378000">LA CRUZ - NARIÑO</option>
              <option value="52381000">LA FLORIDA - NARIÑO</option>
              <option value="52385000">LA LLANADA - NARIÑO</option>
              <option value="52390000">LA TOLA - NARIÑO</option>
              <option value="52399000">LA UNION - NARIÑO</option>
              <option value="52405000">LEIVA - NARIÑO</option>
              <option value="52411000">LINARES - NARIÑO</option>
              <option value="52418000">LOS ANDES - NARIÑO</option>
              <option value="52427000">MAGUI - NARIÑO</option>
              <option value="52435000">MALLAMA - NARIÑO</option>
              <option value="52473000">MOSQUERA - NARIÑO</option>
              <option value="52480000">NARIÑO - NARIÑO</option>
              <option value="52490000">OLAYA HERRERA - NARIÑO</option>
              <option value="52506000">OSPINA - NARIÑO</option>
              <option value="52520000">FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52540000">POLICARPA - NARIÑO</option>
              <option value="52560000">POTOSI - NARIÑO</option>
              <option value="52565000">PROVIDENCIA - NARIÑO</option>
              <option value="52573000">PUERRES - NARIÑO</option>
              <option value="52585000">PUPIALES - NARIÑO</option>
              <option value="52612000">RICAURTE - NARIÑO</option>
              <option value="52621000">ROBERTO PAYAN - NARIÑO</option>
              <option value="52678000">SAMANIEGO - NARIÑO</option>
              <option value="52683000">SANDONA - NARIÑO</option>
              <option value="52685000">SAN BERNARDO - NARIÑO</option>
              <option value="52687000">SAN LORENZO - NARIÑO</option>
              <option value="52693000">SAN PABLO - NARIÑO</option>
              <option value="52694000">SAN PEDRO DE CARTAGO - NARIÑO</option>
              <option value="52696000">SANTA BARBARA - NARIÑO</option>
              <option value="52699000">SANTACRUZ - NARIÑO</option>
              <option value="52720000">SAPUYES - NARIÑO</option>
              <option value="52786000">TAMINANGO - NARIÑO</option>
              <option value="52788000">TANGUA - NARIÑO</option>
              <option value="52835000">TUMACO - NARIÑO</option>
              <option value="52838000">TUQUERRES - NARIÑO</option>
              <option value="52885000">YACUANQUER - NARIÑO</option>
              <option value="54001000">CUCUTA - NORTE DE SANTANDER</option>
              <option value="54003000">ABREGO - NORTE DE SANTANDER</option>
              <option value="54051000">ARBOLEDAS - NORTE DE SANTANDER</option>
              <option value="54099000">BOCHALEMA - NORTE DE SANTANDER</option>
              <option value="54109000">BUCARASICA - NORTE DE SANTANDER</option>
              <option value="54125000">CACOTA - NORTE DE SANTANDER</option>
              <option value="54128000">CACHIRA - NORTE DE SANTANDER</option>
              <option value="54172000">CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54174000">CHITAGA - NORTE DE SANTANDER</option>
              <option value="54206000">CONVENCION - NORTE DE SANTANDER</option>
              <option value="54223000">CUCUTILLA - NORTE DE SANTANDER</option>
              <option value="54239000">DURANIA - NORTE DE SANTANDER</option>
              <option value="54245000">EL CARMEN - NORTE DE SANTANDER</option>
              <option value="54250000">EL TARRA - NORTE DE SANTANDER</option>
              <option value="54261000">EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54313000">GRAMALOTE - NORTE DE SANTANDER</option>
              <option value="54344000">HACARI - NORTE DE SANTANDER</option>
              <option value="54347000">HERRAN - NORTE DE SANTANDER</option>
              <option value="54377000">LABATECA - NORTE DE SANTANDER</option>
              <option value="54385000">LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54398000">LA PLAYA - NORTE DE SANTANDER</option>
              <option value="54405000">LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54418000">LOURDES - NORTE DE SANTANDER</option>
              <option value="54480000">MUTISCUA - NORTE DE SANTANDER</option>
              <option value="54498000">OCAÑA - NORTE DE SANTANDER</option>
              <option value="54518000">PAMPLONA - NORTE DE SANTANDER</option>
              <option value="54520000">PAMPLONITA - NORTE DE SANTANDER</option>
              <option value="54553000">PUERTO SANTANDER - NORTE DE SANTANDER</option>
              <option value="54599000">RAGONVALIA - NORTE DE SANTANDER</option>
              <option value="54660000">SALAZAR - NORTE DE SANTANDER</option>
              <option value="54670000">SAN CALIXTO - NORTE DE SANTANDER</option>
              <option value="54673000">SAN CAYETANO - NORTE DE SANTANDER</option>
              <option value="54680000">SANTIAGO - NORTE DE SANTANDER</option>
              <option value="54720000">SARDINATA - NORTE DE SANTANDER</option>
              <option value="54743000">SILOS - NORTE DE SANTANDER</option>
              <option value="54800000">TEORAMA - NORTE DE SANTANDER</option>
              <option value="54810000">TIBU - NORTE DE SANTANDER</option>
              <option value="54820000">TOLEDO - NORTE DE SANTANDER</option>
              <option value="54871000">VILLA CARO - NORTE DE SANTANDER</option>
              <option value="54874000">VILLA DEL ROSARIO - NORTE DE SANTANDER</option>
              <option value="86001000">MOCOA - PUTUMAYO</option>
              <option value="86219000">COLON - PUTUMAYO</option>
              <option value="86320000">ORITO - PUTUMAYO</option>
              <option value="86568000">PUERTO ASIS - PUTUMAYO</option>
              <option value="86569000">PUERTO CAICEDO - PUTUMAYO</option>
              <option value="86571000">PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86573000">PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86749000">SIBUNDOY - PUTUMAYO</option>
              <option value="86755000">SAN FRANCISCO - PUTUMAYO</option>
              <option value="86757000">SAN MIGUEL - PUTUMAYO</option>
              <option value="86760000">SANTIAGO - PUTUMAYO</option>
              <option value="86865000">VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86885000">VILLAGARZON - PUTUMAYO</option>
              <option value="63001000">ARMENIA - QUINDIO</option>
              <option value="63111000">BUENAVISTA - QUINDIO</option>
              <option value="63130000">CALARCA - QUINDIO</option>
              <option value="63190000">CIRCASIA - QUINDIO</option>
              <option value="63212000">CORDOBA - QUINDIO</option>
              <option value="63272000">FILANDIA - QUINDIO</option>
              <option value="63302000">GENOVA - QUINDIO</option>
              <option value="63401000">LA TEBAIDA - QUINDIO</option>
              <option value="63470000">MONTENEGRO - QUINDIO</option>
              <option value="63548000">PIJAO - QUINDIO</option>
              <option value="63594000">QUIMBAYA - QUINDIO</option>
              <option value="63690000">SALENTO - QUINDIO</option>
              <option value="66001000">PEREIRA - RISARALDA</option>
              <option value="66045000">APIA - RISARALDA</option>
              <option value="66075000">BALBOA - RISARALDA</option>
              <option value="66088000">BELEN DE UMBRIA - RISARALDA</option>
              <option value="66170000">DOSQUEBRADAS - RISARALDA</option>
              <option value="66318000">GUATICA - RISARALDA</option>
              <option value="66383000">LA CELIA - RISARALDA</option>
              <option value="66400000">LA VIRGINIA - RISARALDA</option>
              <option value="66440000">MARSELLA - RISARALDA</option>
              <option value="66456000">MISTRATO - RISARALDA</option>
              <option value="66572000">PUEBLO RICO - RISARALDA</option>
              <option value="66594000">QUINCHIA - RISARALDA</option>
              <option value="66682000">SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66687000">SANTUARIO - RISARALDA</option>
              <option value="88001000">SAN ANDRES - SAN ANDRES</option>
              <option value="88564000">PROVIDENCIA - SAN ANDRES</option>
              <option value="68001000">BUCARAMANGA - SANTANDER</option>
              <option value="68013000">AGUADA - SANTANDER</option>
              <option value="68020000">ALBANIA - SANTANDER</option>
              <option value="68051000">ARATOCA - SANTANDER</option>
              <option value="68077000">BARBOSA - SANTANDER</option>
              <option value="68079000">BARICHARA - SANTANDER</option>
              <option value="68081000">BARRANCABERMEJA - SANTANDER</option>
              <option value="68092000">BETULIA - SANTANDER</option>
              <option value="68101000">BOLIVAR - SANTANDER</option>
              <option value="68121000">CABRERA - SANTANDER</option>
              <option value="68132000">CALIFORNIA - SANTANDER</option>
              <option value="68147000">CAPITANEJO - SANTANDER</option>
              <option value="68152000">CARCASI - SANTANDER</option>
              <option value="68160000">CEPITA - SANTANDER</option>
              <option value="68162000">CERRITO - SANTANDER</option>
              <option value="68167000">CHARALA - SANTANDER</option>
              <option value="68169000">CHARTA - SANTANDER</option>
              <option value="68176000">CHIMA - SANTANDER</option>
              <option value="68179000">CHIPATA - SANTANDER</option>
              <option value="68190000">CIMITARRA - SANTANDER</option>
              <option value="68207000">CONCEPCION - SANTANDER</option>
              <option value="68209000">CONFINES - SANTANDER</option>
              <option value="68211000">CONTRATACION - SANTANDER</option>
              <option value="68217000">COROMORO - SANTANDER</option>
              <option value="68229000">CURITI - SANTANDER</option>
              <option value="68235000">EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68245000">EL GUACAMAYO - SANTANDER</option>
              <option value="68250000">EL PEÑON - SANTANDER</option>
              <option value="68255000">EL PLAYON - SANTANDER</option>
              <option value="68264000">ENCINO - SANTANDER</option>
              <option value="68266000">ENCISO - SANTANDER</option>
              <option value="68271000">FLORIAN - SANTANDER</option>
              <option value="68276000">FLORIDABLANCA - SANTANDER</option>
              <option value="68296000">GALAN - SANTANDER</option>
              <option value="68298000">GAMBITA - SANTANDER</option>
              <option value="68307000">GIRON - SANTANDER</option>
              <option value="68318000">GUACA - SANTANDER</option>
              <option value="68320000">GUADALUPE - SANTANDER</option>
              <option value="68322000">GUAPOTA - SANTANDER</option>
              <option value="68324000">GUAVATA - SANTANDER</option>
              <option value="68327000">GUEPSA - SANTANDER</option>
              <option value="68344000">HATO - SANTANDER</option>
              <option value="68368000">JESUS MARIA - SANTANDER</option>
              <option value="68370000">JORDAN - SANTANDER</option>
              <option value="68377000">LA BELLEZA - SANTANDER</option>
              <option value="68385000">LANDAZURI - SANTANDER</option>
              <option value="68397000">LA PAZ - SANTANDER</option>
              <option value="68406000">LEBRIJA - SANTANDER</option>
              <option value="68418000">LOS SANTOS - SANTANDER</option>
              <option value="68425000">MACARAVITA - SANTANDER</option>
              <option value="68432000">MALAGA - SANTANDER</option>
              <option value="68444000">MATANZA - SANTANDER</option>
              <option value="68464000">MOGOTES - SANTANDER</option>
              <option value="68468000">MOLAGAVITA - SANTANDER</option>
              <option value="68498000">OCAMONTE - SANTANDER</option>
              <option value="68500000">OIBA - SANTANDER</option>
              <option value="68502000">ONZAGA - SANTANDER</option>
              <option value="68522000">PALMAR - SANTANDER</option>
              <option value="68524000">PALMAS DEL SOCORRO - SANTANDER</option>
              <option value="68533000">PARAMO - SANTANDER</option>
              <option value="68547000">PIEDECUESTA - SANTANDER</option>
              <option value="68549000">PINCHOTE - SANTANDER</option>
              <option value="68572000">PUENTE NACIONAL - SANTANDER</option>
              <option value="68573000">PUERTO PARRA - SANTANDER</option>
              <option value="68575000">PUERTO WILCHES - SANTANDER</option>
              <option value="68615000">RIONEGRO - SANTANDER</option>
              <option value="68655000">SABANA DE TORRES - SANTANDER</option>
              <option value="68669000">SAN ANDRES - SANTANDER</option>
              <option value="68673000">SAN BENITO - SANTANDER</option>
              <option value="68679000">SAN GIL - SANTANDER</option>
              <option value="68682000">SAN JOAQUIN - SANTANDER</option>
              <option value="68684000">SAN JOSE DE MIRANDA - SANTANDER</option>
              <option value="68686000">SAN MIGUEL - SANTANDER</option>
              <option value="68689000">SAN VICENTE DE CHUCURI - SANTANDER</option>
              <option value="68705000">SANTA BARBARA - SANTANDER</option>
              <option value="68720000">SANTA HELENA DEL OPON - SANTANDER</option>
              <option value="68745000">SIMACOTA - SANTANDER</option>
              <option value="68755000">SOCORRO - SANTANDER</option>
              <option value="68770000">SUAITA - SANTANDER</option>
              <option value="68773000">SUCRE - SANTANDER</option>
              <option value="68780000">SURATA - SANTANDER</option>
              <option value="68820000">TONA - SANTANDER</option>
              <option value="68855000">VALLE DE SAN JOSE - SANTANDER</option>
              <option value="68861000">VELEZ - SANTANDER</option>
              <option value="68867000">VETAS - SANTANDER</option>
              <option value="68872000">VILLANUEVA - SANTANDER</option>
              <option value="68895000">ZAPATOCA - SANTANDER</option>
              <option value="70001000">SINCELEJO - SUCRE</option>
              <option value="70110000">BUENAVISTA - SUCRE</option>
              <option value="70124000">CAIMITO - SUCRE</option>
              <option value="70204000">COLOSO - SUCRE</option>
              <option value="70215000">COROZAL - SUCRE</option>
              <option value="70221000">COVEÑAS - SUCRE</option>
              <option value="70230000">CHALAN - SUCRE</option>
              <option value="70233000">EL ROBLE - SUCRE</option>
              <option value="70235000">GALERAS - SUCRE</option>
              <option value="70265000">GUARANDA - SUCRE</option>
              <option value="70400000">LA UNION - SUCRE</option>
              <option value="70418000">LOS PALMITOS - SUCRE</option>
              <option value="70429000">MAJAGUAL - SUCRE</option>
              <option value="70473000">MORROA - SUCRE</option>
              <option value="70508000">OVEJAS - SUCRE</option>
              <option value="70523000">PALMITO - SUCRE</option>
              <option value="70670000">SAMPUES - SUCRE</option>
              <option value="70678000">SAN BENITO ABAD - SUCRE</option>
              <option value="70702000">SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70708000">SAN MARCOS - SUCRE</option>
              <option value="70713000">SAN ONOFRE - SUCRE</option>
              <option value="70717000">SAN PEDRO - SUCRE</option>
              <option value="70742000">SAN LUIS DE SINCE - SUCRE</option>
              <option value="70771000">SUCRE - SUCRE</option>
              <option value="70820000">SANTIAGO DE TOLU - SUCRE</option>
              <option value="70823000">TOLU VIEJO - SUCRE</option>
              <option value="73001000">IBAGUE - TOLIMA</option>
              <option value="73024000">ALPUJARRA - TOLIMA</option>
              <option value="73026000">ALVARADO - TOLIMA</option>
              <option value="73030000">AMBALEMA - TOLIMA</option>
              <option value="73043000">ANZOATEGUI - TOLIMA</option>
              <option value="73055000">ARMERO - TOLIMA</option>
              <option value="73067000">ATACO - TOLIMA</option>
              <option value="73124000">CAJAMARCA - TOLIMA</option>
              <option value="73148000">CARMEN DE APICALA - TOLIMA</option>
              <option value="73152000">CASABIANCA - TOLIMA</option>
              <option value="73168000">CHAPARRAL - TOLIMA</option>
              <option value="73200000">COELLO - TOLIMA</option>
              <option value="73217000">COYAIMA - TOLIMA</option>
              <option value="73226000">CUNDAY - TOLIMA</option>
              <option value="73236000">DOLORES - TOLIMA</option>
              <option value="73268000">ESPINAL - TOLIMA</option>
              <option value="73270000">FALAN - TOLIMA</option>
              <option value="73275000">FLANDES - TOLIMA</option>
              <option value="73283000">FRESNO - TOLIMA</option>
              <option value="73319000">GUAMO - TOLIMA</option>
              <option value="73347000">HERVEO - TOLIMA</option>
              <option value="73349000">HONDA - TOLIMA</option>
              <option value="73352000">ICONONZO - TOLIMA</option>
              <option value="73408000">LERIDA - TOLIMA</option>
              <option value="73411000">LIBANO - TOLIMA</option>
              <option value="73443000">SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73449000">MELGAR - TOLIMA</option>
              <option value="73461000">MURILLO - TOLIMA</option>
              <option value="73483000">NATAGAIMA - TOLIMA</option>
              <option value="73504000">ORTEGA - TOLIMA</option>
              <option value="73520000">PALOCABILDO - TOLIMA</option>
              <option value="73547000">PIEDRAS - TOLIMA</option>
              <option value="73555000">PLANADAS - TOLIMA</option>
              <option value="73563000">PRADO - TOLIMA</option>
              <option value="73585000">PURIFICACION - TOLIMA</option>
              <option value="73616000">RIOBLANCO - TOLIMA</option>
              <option value="73622000">RONCESVALLES - TOLIMA</option>
              <option value="73624000">ROVIRA - TOLIMA</option>
              <option value="73671000">SALDAÑA - TOLIMA</option>
              <option value="73675000">SAN ANTONIO - TOLIMA</option>
              <option value="73678000">SAN LUIS - TOLIMA</option>
              <option value="73686000">SANTA ISABEL - TOLIMA</option>
              <option value="73770000">SUAREZ - TOLIMA</option>
              <option value="73854000">VALLE DE SAN JUAN - TOLIMA</option>
              <option value="73861000">VENADILLO - TOLIMA</option>
              <option value="73870000">VILLAHERMOSA - TOLIMA</option>
              <option value="73873000">VILLARRICA - TOLIMA</option>
              <option value="76001000">CALI - VALLE DEL CAUCA</option>
              <option value="76020000">ALCALA - VALLE DEL CAUCA</option>
              <option value="76036000">ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76041000">ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76054000">ARGELIA - VALLE DEL CAUCA</option>
              <option value="76100000">BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76109000">BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76111000">GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76113000">BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76122000">CAICEDONIA - VALLE DEL CAUCA</option>
              <option value="76126000">CALIMA - VALLE DEL CAUCA</option>
              <option value="76130000">CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76147000">CARTAGO - VALLE DEL CAUCA</option>
              <option value="76233000">DAGUA - VALLE DEL CAUCA</option>
              <option value="76243000">EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76246000">EL CAIRO - VALLE DEL CAUCA</option>
              <option value="76248000">EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76250000">EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76275000">FLORIDA - VALLE DEL CAUCA</option>
              <option value="76306000">GINEBRA - VALLE DEL CAUCA</option>
              <option value="76318000">GUACARI - VALLE DEL CAUCA</option>
              <option value="76364000">JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76377000">LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76400000">LA UNION - VALLE DEL CAUCA</option>
              <option value="76403000">LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76497000">OBANDO - VALLE DEL CAUCA</option>
              <option value="76520000">PALMIRA - VALLE DEL CAUCA</option>
              <option value="76563000">PRADERA - VALLE DEL CAUCA</option>
              <option value="76606000">RESTREPO - VALLE DEL CAUCA</option>
              <option value="76616000">RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76622000">ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76670000">SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76736000">SEVILLA - VALLE DEL CAUCA</option>
              <option value="76823000">TORO - VALLE DEL CAUCA</option>
              <option value="76828000">TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76834000">TULUA - VALLE DEL CAUCA</option>
              <option value="76845000">ULLOA - VALLE DEL CAUCA</option>
              <option value="76863000">VERSALLES - VALLE DEL CAUCA</option>
              <option value="76869000">VIJES - VALLE DEL CAUCA</option>
              <option value="76890000">YOTOCO - VALLE DEL CAUCA</option>
              <option value="76892000">YUMBO - VALLE DEL CAUCA</option>
              <option value="76895000">ZARZAL - VALLE DEL CAUCA</option>
              <option value="97001000">MITU - VAUPES</option>
              <option value="97161000">CARURU - VAUPES</option>
              <option value="97511000">PACOA - VAUPES</option>
              <option value="97666000">TARAIRA - VAUPES</option>
              <option value="97777000">PAPUNAUA - VAUPES</option>
              <option value="97889000">YAVARATE - VAUPES</option>
              <option value="99001000">PUERTO CARREÑO - VICHADA</option>
              <option value="99524000">LA PRIMAVERA - VICHADA</option>
              <option value="99624000">SANTA ROSALIA - VICHADA</option>
              <option value="99773000">CUMARIBO - VICHADA</option>
              <option value="1000001">Ciudad de Mexico</option>
              <option value="91263000">EL ENCANTO - AMAZONAS</option>
              <option value="91405000">LA CHORRERA - AMAZONAS</option>
              <option value="91407000">LA PEDRERA - AMAZONAS</option>
              <option value="91001000">LETICIA - AMAZONAS</option>
              <option value="91430000">LA VICTORIA PACOA - AMAZONAS</option>
              <option value="91460000">MIRITI - PARANA - AMAZONAS</option>
              <option value="91530000">PUERTO ALEGRIA - AMAZONAS</option>
              <option value="91536000">PUERTO ARICA - AMAZONAS</option>
              <option value="91540000">PUERTO NARIÑO - AMAZONAS</option>
              <option value="91669000">PUERTO SANTANDER - AMAZONAS</option>
              <option value="91798000">TARAPACA - AMAZONAS</option>
              <option value="05002000">ABEJORRAL - ANTIOQUIA</option>
              <option value="05004000">ABRIAQUI - ANTIOQUIA</option>
              <option value="05021000">ALEJANDRIA - ANTIOQUIA</option>
              <option value="05030000">AMAGA - ANTIOQUIA</option>
              <option value="05031000">AMALFI - ANTIOQUIA</option>
              <option value="05034000">ANDES - ANTIOQUIA</option>
              <option value="05036000">ANGELOPOLIS - ANTIOQUIA</option>
              <option value="05038000">ANGOSTURA - ANTIOQUIA</option>
              <option value="05040000">ANORI - ANTIOQUIA</option>
              <option value="05042000">SANTAFE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05044000">ANZA - ANTIOQUIA</option>               
              <option value="05837001">CURRULAO - ANTIOQUIA</option>
              <option value="05591004">DORADAL - ANTIOQUIA</option>
              <option value="20250002">LA LOMA - CESAR</option>
              <option value="52215002">LLORENTE - NARIÑO</option>
              <option value="76130008">VILLA GORGONA - VALLE DEL CAUCA</option>
              <option value="81065008">LA ESMERALDA - ARAUCA</option>
              <option value="08832000">TUBARA - ATLÁNTICO</option>
              <option value="86865000">LA HORMIGA - PUTUMAYO</option>
              <option value="73616001">HERRERA - TOLIMA</option>
            </Select>
          </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Teléfono"
              name="telefono"
              rules={[{ required: true, message: 'Por favor, ingresa el número de teléfono' }]}
            >
              <Input placeholder="Ingresa el número de teléfono" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Correo Electrónico"
              name="correo"
              rules={[
                { required: true, message: 'Por favor, ingresa el correo electrónico' },
                { type: 'email', message: 'Por favor, ingresa un correo electrónico válido' },
              ]}
            >
              <Input placeholder="Ingresa el correo electrónico" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Contraseña"
              name="contrasena"
              rules={[{ required: true, message: 'Por favor, ingresa la contraseña' }]}
            >
              <Input.Password
                placeholder="Ingresa la contraseña"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Nombre de la Sucursal"
              name="nombre_sucursal"
              rules={[{ required: true, message: 'Por favor, ingresa el nombre de la sucursal' }]}
            >
              <Input placeholder="Ingresa el nombre de la sucursal" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            style={{ backgroundColor: '#8c8cff', maxWidth: '20%', margin: '2% auto 0' }}
          >
            Crear Sucursal
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CrearSucursal;
