import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarSales from '../../components/navbar/navbarSales';
import './dashboardSales.css';
import {  Tag } from 'antd';
import 'antd/dist/antd.css'; // o la versión que corresponda de antd

const DashboardSales = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [sucursalesData, setSucursalesData] = useState([]);
  const userId = localStorage.getItem('usuario_id'); // Get user ID from localStorage

  const fetchData = async () => {
    try {
      const [dashboardResponse, sucursalesResponse] = await Promise.all([
        axios.get(`https://api.99envios.app/api/online/vendedor/${userId}`),
        axios.get(`https://api.99envios.app/api/online/vendedor-sucursales/${userId}`)
      ]);
      setDashboardData(dashboardResponse.data);
      setSucursalesData(sucursalesResponse.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: (a, b) => a.codigo - b.codigo,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
      filters: [...new Set(sucursalesData.map(item => item.ciudad))].map(ciudad => ({
        text: ciudad,
        value: ciudad,
      })),
      onFilter: (value, record) => record.ciudad.includes(value),
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'Total Envíos',
      dataIndex: 'total_preenvios',
      key: 'total_preenvios',
      sorter: (a, b) => a.total_preenvios - b.total_preenvios,
      render: (total) => (
        <Tag color={total === 0 ? 'red' : total > 10 ? 'green' : 'orange'}>
          {total}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <NavbarSales title={"Vendedor"}/>
      <div className="dashboard-containerAD">
        {dashboardData ? (
          <div className="cards-containerAD">
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD local-shipping-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  work_outline  </span>
                </div>
                <h3>Total Sucursales</h3>
                <p>{dashboardData["$totalSucursales"]}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD check-circle-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  sick  </span>
                </div>
                <h3>Sucursales sin Envios</h3>
                <p>{dashboardData.sucursalesCeroEnvios}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD report-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  elevator  </span>
                </div>
                <h3>Sucursales con 10 Envios</h3>
                <p>{dashboardData["$totalSucursales10"]}</p>
              </div>
            </div>
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD track-changes-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  transfer_within_a_station  </span>
                </div>
                <h3>Sucursales con 20 Envios</h3>
                <p>{dashboardData["$totalSucursales20"]}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD public-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  get_app  </span>
                </div>
                <h3>Sucursales con 30 Envios</h3>
                <p>{dashboardData["$totalSucursales30"]}</p>
              </div>
            </div>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
        
        {/* Remove the table-containerAD div */}
      </div>
    </>
  );
}

export default DashboardSales;