import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavbarMex from '../components/navbarMex';
import sampleFile from './envio_masivo.xlsx';
import * as XLSX from 'xlsx';
import { Table, Button, Upload, Select, Row, Col, Spin, Form, message, Alert, Modal } from 'antd'; 
import { UploadOutlined } from '@ant-design/icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import 'antd/dist/antd.css';
import './ExcelUploader.css';

const { Option } = Select;

const ExcelUploader = () => {
  const { id } = useParams(); 
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cotizacionResults, setCotizacionResults] = useState({});
  const [selectedTransportadora, setSelectedTransportadora] = useState('');
  const [selectedTipoEnvio, setSelectedTipoEnvio] = useState('');
  const [sucursalId, setSucursalId] = useState('');
  const [allCotizacionesDone, setAllCotizacionesDone] = useState(false);
  const [showTipoEnvioSelect, setShowTipoEnvioSelect] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false); // Estado de carga para el PDF


  const transportadoras = ["interrapidisimo", "tcc", "servientrega", "coordinadora"];
  const tipoEnvio = ["envio puerta a puerta", "entrega en oficina"];

  const handleFileUpload = ({ file }) => {
    setLoadingPdf(true);
    const reader = new FileReader();
    

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      
      // Filtrar filas vacías o inválidas
      const filteredData = jsonData.filter(row => {
        // Verificar si la fila tiene al menos algunos campos obligatorios
        const hasRequiredFields = 
          row['numero documento'] || // ID del destinatario
          row['nombre'] || // Nombre del destinatario
          row['codigo destino'] || // Código de ciudad
          row['direccion']; // Dirección

        // Si tiene al menos uno de los campos requeridos, verificamos que no sea una fila completamente vacía
        if (hasRequiredFields) {
          // Verificar si hay al menos un campo con valor no vacío
          return Object.values(row).some(value => 
            value !== undefined && 
            value !== null && 
            value.toString().trim() !== ''
          );
        }
        return false;
      });

      // Limpiar los datos y asegurarse de que los campos numéricos sean números
      const cleanedData = filteredData.map(row => ({
        ...row,
        'Peso': parseFloat(row['Peso']) || 0,
        'valor declarado': parseFloat(row['valor declarado']) || 50000,
        'AplicaContrapago': Boolean(row['AplicaContrapago']),
        'numero documento': row['numero documento']?.toString().trim(),
        'nombre': row['nombre']?.toString().trim(),
        'direccion': row['direccion']?.toString().trim(),
        'codigo destino': row['codigo destino']?.toString().trim(),
        'dice contener': row['dice contener']?.toString().trim(),
        'primer apellido': row['primer apellido']?.toString().trim(),
        'segundo apellido': row['segundo apellido']?.toString().trim(),
        'Observaciones': row['Observaciones']?.toString().trim()
      }));

      setData(cleanedData);
      setAllCotizacionesDone(false);
      setLoadingPdf(false);
    };

    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem('id', id);
    }
  }, [id]);

  useEffect(() => { 
    const fetchSucursalId = async () => {
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`);
        if (!response.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        const data = await response.json();
        console.log('Sucursal ID:', data);
        setSucursalId(data); // Actualiza el estado con el ID de la sucursal
      } catch (error) {
        console.error('Error fetching sucursal ID:', error);
        return null;
      }
    };
  
    fetchSucursalId();
  }, [id]);

  const handleCotizar = async () => {
    setLoading(true);
    const results = {};
    let failedCotizaciones = [];
  
    const reintentarCotizar = async (formData, retries = 3) => {
      let attempt = 0;
      let result = null;
  
      while (attempt < retries) {
        attempt++;
        try {
          const cotizacionData = {
            destino: {
              nombre: "",
              codigo: formData['codigo destino'],
            },
            origen: {
              nombre: "",
              codigo: "",
            },
            IdTipoEntrega: selectedTransportadora === 'interrapidisimo' ? (selectedTipoEnvio === 'envio puerta a puerta' ? 1 : 2) : 1, // Establecer IdTipoEntrega a 1 para el resto de transportadoras
            IdServicio: 1,
            peso: formData.Peso,
            largo: 1,
            ancho: 1,
            alto: 1,
            fecha: "28-06-2024",
            AplicaContrapago: formData.AplicaContrapago,
            valorDeclarado: parseInt(formData['valor declarado'], 10),
            transportadora: {
              pais: "colombia",
              nombre: selectedTransportadora,
            },
            origenCreacion: 1,
          };
  
          const response = await fetch(`https://integration.99envios.app/api/sucursal/cotizar_individual/${sucursalId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(cotizacionData),
          });
  
          if (!response.ok) {
            throw new Error('Error en la cotización');
          }
  
          result = await response.json();
          if (result.exito) {
            return result;  // Si es exitosa, retornamos el resultado
          } else {
            throw new Error(result.mensaje); // Si no fue exitosa, lanzamos el mensaje de error
          }
        } catch (error) {
          console.error(`Intento ${attempt} fallido para cotización de documento ${formData['numero documento']}:`, error);
        }
      }
      // Si después de los reintentos no se pudo cotizar, registramos el fallo
      failedCotizaciones.push(formData['numero documento']);
      return null;
    };
  
    for (const formData of data) {
      if (!formData['numero documento']) {
        message.error(`El campo "numero documento" es obligatorio para el destinatario con nombre ${formData.nombre}`);
        continue; // Saltar si falta el número de documento
      }
  
      const diceContenerValue = formData['dice contener'] ? formData['dice contener'].toString() : '';
      if (!diceContenerValue) {
        message.error(`El campo "Dice Contener" es obligatorio para el destinatario con documento ${formData['numero documento']}`);
        continue; // Saltar si falta el campo obligatorio
      }
  
      const cotizacionResult = await reintentarCotizar(formData);
      if (cotizacionResult) {
        results[formData['numero documento']] = cotizacionResult;
      }
    }
  
    setCotizacionResults(results);
    setLoading(false);
  
    const allDone = data.every(item => results[item['numero documento']]);
    setAllCotizacionesDone(allDone);
  
    // Mostrar un mensaje de error si hubo fallos en algunas cotizaciones
    if (failedCotizaciones.length > 0) {
      message.error(`Error en la cotización de los siguientes documentos: ${failedCotizaciones.join(', ')}`);
    }
  };

  const handleTransportadoraChange = (value) => {
    setSelectedTransportadora(value);
    setShowTipoEnvioSelect(value === 'interrapidisimo');
    if (value === 'interrapidisimo') {
      setSelectedTipoEnvio('');
    } else {
      setSelectedTipoEnvio('envio puerta a puerta');
    }
  };

  const handleTipoEnvioChange = (value) => {
    setSelectedTipoEnvio(value);
  };
  
  const requestPdf = async (pdfData, retries = 3) => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        attempt++;
        const pdfResponse = await fetch(
          `https://integration.99envios.app/api/sucursal/pdf/${sucursalId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(pdfData),
          }
        );
  
        if (!pdfResponse.ok) {
          const pdfErrorData = await pdfResponse.json();
          const pdfErrorMessage = pdfErrorData.mensaje_completo
            ? pdfErrorData.mensaje_completo.join(' ')
            : pdfErrorData.mensaje;
          throw new Error(`Error al generar PDF: ${pdfErrorMessage}`);
        }
  
        // Maneja la URL directa del PDF
        const pdfUrl = await pdfResponse.text(); // La API responde con una URL directa
        if (!pdfUrl) throw new Error('No se recibió la URL del PDF');
        return pdfUrl; // Devuelve la URL
      } catch (error) {
        console.error(`Intento ${attempt} fallido para generar el PDF`, error);
        if (attempt >= retries) throw new Error('Error persistente en la generación del PDF');
      }
    }
  };

  const handlePreenvio = async (formDataGuia, index) => {
    const cotizacion = cotizacionResults[formDataGuia['numero documento']];
    if (!cotizacion) {
      message.error(`No se ha cotizado el envío para el destinatario con documento ${formDataGuia['numero documento']}`);
      return null;
    }
  
    const pesoFinal = formDataGuia.Peso;
    const valorDeclaradoLimpio = parseFloat(formDataGuia['valor declarado']) || 50000;
    const formattedDate = "28-06-2024";
  
    const diceContenerValue = formDataGuia['dice contener'] ? formDataGuia['dice contener'].toString() : '';
  
    if (!diceContenerValue) {
      message.error(`El campo "Dice Contener" es obligatorio para el destinatario con documento ${formDataGuia['numero documento']}`);
      return null;
    }
  
    const guiaData = {
      IdTipoEntrega: selectedTipoEnvio === 'envio puerta a puerta' ? 1 : 2,
      IdServicio: 1,
      AplicaContrapago: formDataGuia.AplicaContrapago || false,
      peso: pesoFinal,
      largo: parseFloat(formDataGuia.Largo) || 1,
      ancho: parseFloat(formDataGuia.Ancho) || 1,
      alto: parseFloat(formDataGuia.Alto) || 1,
      diceContener: diceContenerValue,
      valorDeclarado: valorDeclaradoLimpio,
      fecha: formattedDate,
      Destinatario: {
        tipoDocumento: formDataGuia.tipoDocumento ? formDataGuia.tipoDocumento.toString() : "CC",
        numeroDocumento: formDataGuia['numero documento'] ? formDataGuia['numero documento'].toString() : `ID-${index}`,
        nombre: formDataGuia.nombre ? formDataGuia.nombre.toString() : "",
        primerApellido: formDataGuia['primer apellido'] ? formDataGuia['primer apellido'].toString() : "",
        segundoApellido: formDataGuia['segundo apellido'] ? formDataGuia['segundo apellido'].toString() : "",
        telefono: formDataGuia.telefono ? formDataGuia.telefono.toString() : "",
        direccion: formDataGuia.direccion ? formDataGuia.direccion.toString() : "",
        correo: formDataGuia.correo ? formDataGuia.correo.toString() : "",
        idLocalidad: formDataGuia['codigo destino'] ? formDataGuia['codigo destino'].toString() : "",
      },
      Observaciones: formDataGuia.Observaciones ? formDataGuia.Observaciones.toString() : "",
      transportadora: {
        pais: "colombia",
        nombre: selectedTransportadora,
        aplicaContrapago: formDataGuia.AplicaContrapago,
      },
      origenCreacion: 1,
    };
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://integration.99envios.app/api/sucursal/preenvio/${sucursalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(guiaData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.mensaje_completo ? errorData.mensaje_completo.join(' ') : errorData.mensaje;
        message.error(`Error: ${errorMessage}`);
        throw new Error('Error en la respuesta del servidor');
      }
  
      const responseData = await response.json();
      const numeroPreenvio = responseData.numeroPreenvio.toString();
  
      const pdfData = {
        transportadora: {
          pais: "colombia",
          nombre: selectedTransportadora,
        },
        guia: numeroPreenvio,
        AplicaContrapago: formDataGuia.AplicaContrapago,
        origenCreacion: 1,
      };
  
      // Llamada a requestPdf
      const pdfUrl = await requestPdf(pdfData); // Ahora llamamos a requestPdf
      return { numeroDocumento: `${formDataGuia['numero documento'] || 'ID'}-${index}`, pdfUrl };
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };
  
  
  

  const handlePreenvioAndZip = async () => {
    setLoading(true);
  
    let failedDocuments = [];
    let successfulPdfs = [];
  
    const reintentarPreenvio = async (row, index, retries = 3) => {
      let attempt = 0;
      let result = null;
  
      while (attempt < retries) {
        attempt++;
        try {
          result = await handlePreenvio(row, index);
          if (result && result.pdfUrl) {
            return result; // Si se obtiene la URL del PDF, regresamos el resultado exitoso
          }
        } catch (error) {
          console.error(`Intento ${attempt} fallido para documento ${row['numero documento']}:`, error);
        }
      }
      failedDocuments.push(row['numero documento'] || `ID-${index}`);
      return null;
    };
  
    try {
      const pdfPromises = data.map((row, index) => reintentarPreenvio(row, index));
      const pdfResults = await Promise.all(pdfPromises);
  
      for (const result of pdfResults) {
        if (result && result.pdfUrl) {
          try {
            // Abrir la URL del PDF en una nueva pestaña
            window.open(result.pdfUrl, '_blank');
            successfulPdfs.push(result.numeroDocumento);
          } catch (error) {
            console.error(`Error al abrir el PDF para ${result.numeroDocumento}:`, error);
            failedDocuments.push(result.numeroDocumento);
          }
        }
      }
  
      // Notificar al usuario sobre los resultados
      message.success(
        `Se generaron y abrieron exitosamente ${successfulPdfs.length} PDFs en nuevas pestañas.`
      );
  
      if (failedDocuments.length > 0) {
        message.error(
          `No se pudieron generar los PDFs para los siguientes documentos: ${failedDocuments.join(', ')}`
        );
      }
    } catch (error) {
      console.error('Error al generar los PDFs:', error);
      message.error('Hubo un error al generar los PDFs');
    } finally {
      setLoading(false);
    }
  };
  
  const columns = [
    { title: 'PRIMER APELLIDO', dataIndex: 'primer apellido', key: 'primer apellido', width: 120 },
    { title: 'SEGUNDO APELLIDO', dataIndex: 'segundo apellido', key: 'segundo apellido', width: 120 },
    { title: 'NOMBRE DESTINATARIO', dataIndex: 'nombre', key: 'nombre', width: 120 },
    { title: 'IDENTIFICACION DESTINATARIO', dataIndex: 'numero documento', key: 'numero documento', width: 150 },
    { title: 'DIRECCIÓN DESTINATARIO', dataIndex: 'direccion', key: 'direccion', width: 200 },
    { title: 'CODIGO CIUDAD DESTINATARIO', dataIndex: 'codigo destino', key: 'codigo destino', width: 150 },
    { title: 'DICE CONTENER', dataIndex: 'dice contener', key: 'dice contener', width: 120 },
    { title: 'OBSERVACIONES', dataIndex: 'Observaciones', key: 'Observaciones', width: 150 },
    { title: 'PESO', dataIndex: 'Peso', key: 'Peso', width: 100 },
    { title: 'VALOR COMERCIAL', dataIndex: 'valor declarado', key: 'valor declarado', width: 150 },
    {
      title: 'Contrapago',
      dataIndex: 'AplicaContrapago',
      key: 'AplicaContrapago',
      width: 100,
      render: (text) => {
        return text === true || text === 'true' || text === 1 || text === '1' ? 'Sí' : 'No';
      }
    },
    {
      title: 'Valor Cotización',
      key: 'cotizacion_valor',
      width: 150,
      render: (text, record) => {
        const cotizacion = cotizacionResults[record['numero documento']];
        if (cotizacion) {
          if (cotizacion.exito) {
            // Calcular el valor total sumando las diferentes partes de la cotización
            const valorTotal = (cotizacion.valor || 0) + (cotizacion.valor_contrapago || 0) + (cotizacion.comision_interna || 0) + (cotizacion.sobreflete || 0);
            return valorTotal;
          } else {
            // Si no hay éxito en la cotización, mostrar el mensaje de error
            return `Error: ${cotizacion.mensaje}`;
          }
        }
        return 'N/A';
      }
    }
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  return (
    <>
      <NavbarMex title={"Envíos Masivo Final"}/>
      <div className="masivoFinal-containerMasivos" >
        <h1>Envio Masivo</h1>

        {/* Mensaje de alerta */}
        <Alert
          message={
            <>
              Para realizar un envío masivo, consulta el siguiente video instructivo:{" "}
              <Button type="link" onClick={showModal} style={{ padding: 0 }}>
                Ver video aquí.
              </Button>
            </>
          }
          type="info"
          showIcon
          style={{ marginBottom: 16 }}
        />

        <Modal
          title="Instructivo de Guías Masivas"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose={true}
          width="100vh"
          style={{ top: 20 }}
          bodyStyle={{ padding: 0 }}
        >
          <div style={{ position: 'relative', paddingBottom: '65%', height: 0, overflow: 'hidden' }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/wSDLo4-E2iA?si=G4_4J-Cp2d-20lE3"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </div>
        </Modal>

        <Row gutter={16} className="masivoFinal-mb-3">
          
          
          <Col span={6}>
            <Form.Item label="Seleccionar archivo" style={{ marginBottom: 8 }}>
              <Upload style={{marginBottom: 9}} beforeUpload={() => false} onChange={handleFileUpload} showUploadList={false}>
                <Button  icon={<UploadOutlined />} size="small">Seleccionar archivo</Button>
              </Upload>
            </Form.Item>
            <a href={sampleFile} download>
              <button className='btnDescargar' type="button">
                Descargar plantilla
              </button>
            </a>
            {loadingPdf && <Spin size="large" className="excel-loading-spinner" />}
          </Col>

          <Col span={6}>
            <Form.Item label="Seleccionar Transportadora" style={{ marginBottom: '8px' }}>
              <Select value={selectedTransportadora} onChange={handleTransportadoraChange} size="small">
                <Option value="">Seleccionar</Option>
                {transportadoras.map(transportadora => (
                  <Option key={transportadora} value={transportadora}>{transportadora}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            {showTipoEnvioSelect && (
              <Form.Item label="Seleccionar Tipo" style={{ marginBottom: '8px' }}>
                <Select value={selectedTipoEnvio} onChange={handleTipoEnvioChange} size="small">
                  <Option value="">Seleccionar</Option>
                  {tipoEnvio.map(tipoE => (
                    <Option key={tipoE} value={tipoE}>{tipoE}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col span={8} style={{ alignItems: 'center' }}>
            <Button
              type="primary"
              onClick={handleCotizar}
              disabled={loading || data.length === 0 || selectedTransportadora === ''}
              className="masivoFinal-me-2"
              size="medium"
              style={{ height: '40px' }}
            >
              Cotizar
            </Button>
            {allCotizacionesDone && (
              <Button
                type="primary"
                onClick={handlePreenvioAndZip}
                disabled={loading}
                className="masivoFinal-me-2"
                size="medium"
                style={{ backgroundColor: 'green', height: '40px' }} 
              >
                Preenvío y Generar ZIP
              </Button>
            )}
            {loading && <Spin className="masivoFinal-ml-2" size="small" />}
          </Col>
        </Row>
        <div className="masivoFinal-table-container" style={{maxWidth:"100%"}}>
          <Table 
            dataSource={data} 
            columns={columns} 
            rowKey="numero documento" 
            bordered 
            size="small"
            pagination={{ pageSize: 5 }} 
            scroll={{ x: 'max-content' }}  // Ajuste de desplazamiento horizontal
          />
        </div>
      </div>
    </>
  );
};

export default ExcelUploader;