import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Dropdown, Menu, Button, Select } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import './misPedidos.css';
import { render } from '@testing-library/react';

const { Option } = Select;

const ProductTablePedidos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    estadoOrden: '',
    transportadora: '',
    estadoEnvio: '',
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.99envios.app/api/listar_pedidos/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: index + 1,
          id_producto: item.id_producto,
          id_variacion: item.id_variacion,
          talla: item.talla,
          color: item.color,
          nombre_producto: item.nombre_producto,
          fecha_estado: item.fecha_estado,
          estado_envio: item.estado_envio,
          transportadora: item.transportadora,
          valor_proveedor: item.precio_real,
          cantidad: item.cantidad,
          valor_total: item.valor_producto * item.cantidad,
          valor_utilidad: item.utilidad,
          numero_guia: item.numero_guia,
          pdf: item.pdf,
          nombre_destinatario: `${item.nombre_destinatario} ${item.primer_apellido_destinatario}`,
          telefono_destinatario: item.telefono_destinatario,
          direccion_destinatario: item.direccion_destinatario,
          selected: false,
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  const handlePdfDownload = (pdfUrl, fileName) => {
    if (pdfUrl) {
      const a = document.createElement('a');
      a.href = pdfUrl; // Usar la URL del PDF directamente
      a.download = fileName;
      a.target = '_blank'; // Abrir en una nueva pestaña, si es necesario
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      alert('PDF no disponible para este envío');
    }
  };
  

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={record.selected}
          onChange={() => handleCheckboxChange(record.key)}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Producto',
      dataIndex: 'id_producto',
      key: 'id_producto',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion',
      key: 'variacion',
      render: (_, record) => {
        const idVariacion = record.id_variacion ? `ID: ${record.id_variacion}` : 'Sin variación';
        const talla = record.talla ? `Talla: ${record.talla}` : 'Sin talla';
        const color = record.color ? `Color: ${record.color}` : 'Sin color';
  
        return (
          <div>
            <p>{idVariacion}</p>
            <p>{talla}</p>
            <p>{color}</p>
          </div>
        );
      },
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Fecha de estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
    },
    {
      title: 'Valor de Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      render: (value) => `$${value.toLocaleString()}`,
    },
    // agregar numero de guia
    {
      title: ' Numero de Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      render: (value) => value ? value : 'Sin guía',
    },
    {
      title: 'Guía',
      dataIndex: 'numero_guia',
      key: 'guia',
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handlePdfDownload(record.pdf, `Guia_${record.numero_guia}.pdf`)}
        >
          Descargar
        </Button>
      ),
    },
    {
      title: 'Destinatario',
      dataIndex: 'nombre_destinatario',
      key: 'nombre_destinatario',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono_destinatario',
      key: 'telefono_destinatario',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion_destinatario',
      key: 'direccion_destinatario',
    },
  ];
  

  const handleCheckboxChange = (key) => {
    const updatedData = data.map(item => 
      item.key === key ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
    setFilteredData(updatedData);
    setSelectedItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filters.estadoOrden) {
      result = result.filter((item) => item.estado_orden === parseInt(filters.estadoOrden));
    }
    if (filters.transportadora) {
      result = result.filter((item) => item.transportadora.toLowerCase() === filters.transportadora.toLowerCase());
    }
    if (filters.estadoEnvio) {
      result = result.filter((item) => item.estado_envio.toLowerCase() === filters.estadoEnvio.toLowerCase());
    }
    setFilteredData(result);
  }, [searchText, filters, data]);

  const downloadPDF = (base64String, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadCombinedPDF = async () => {
    const selectedData = data.filter(item => selectedItems.includes(item.key));
    const pdfDocs = await Promise.all(
      selectedData.map(async (item) => {
        const pdfBytes = await fetch(`data:application/pdf;base64,${item.pdf}`).then(res => res.arrayBuffer());
        return pdfBytes;
      })
    );

    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfDocs) {
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'combined.pdf';
    a.click();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={downloadCombinedPDF}>Descargar seleccionados como PDF combinado</Menu.Item>
    </Menu>
  );

  return (
    <>
      <NavbarDropshipping title="Órdenes y Envíos Bodega" />
      <div className='misPedidos'>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Dropdown overlay={menu}>
            <Button>
              Acciones <DownOutlined />
            </Button>
          </Dropdown>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <Select
            style={{ width: 200, marginRight: '8px' }}
            placeholder="Filtrar por Transportadora"
            onChange={(value) => handleFilterChange('transportadora', value)}
          >
            <Option value="">Todas</Option>
            <Option value="interrapidisimo">Interrapidisimo</Option>
            <Option value="coordinadora">Coordinadora</Option>
            <Option value="servientrega">Servientrega</Option>
            <Option value="envia">Envia</Option>
          </Select>
          <Select
            style={{ width: 200 }}
            placeholder="Filtrar por Estado de Envío"
            onChange={(value) => handleFilterChange('estadoEnvio', value)}
          >
            <Option value="">Todos</Option>
            <Option value="creado">Creado</Option>
            <Option value="en_transito">En tránsito</Option>
            <Option value="entregado">Entregado</Option>
          </Select>
        </div>
        <div className="misPedidos-tabla" style={{ overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
    </>
  );
};

export default ProductTablePedidos;
