import React, { useState } from 'react';
import NavbarAdminMex from '../components/navbar';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import { Modal, message } from 'antd';
import './eliminarGuia.css';

const DesactivarGuia = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('interrapidisimo');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleCarrierChange = (event) => {
    setCarrier(event.target.value);
  };

  const handleErrorMessage = (message) => {
    const errorMessages = {
      'Sucursal no encontrada': 'Sucursal no encontrada',
      'Transportadora no encontrada': 'Transportadora no encontrada',
      'Usuario no encontrado': 'Usuario no encontrado',
      'Usuario no autorizado para utilizar este servicio': 'Usuario no autorizado para utilizar este servicio',
      'Preenvío no encontrado': 'Preenvío no encontrado',
      'Este preenvío ya ha sido desactivado o tiene un estado que no permite modificaciones.': 
        'Este preenvío ya ha sido desactivado o tiene un estado que no permite modificaciones.',
      'No se encontraron transacciones para actualizar.': 'No se encontraron transacciones para actualizar.',
      'Error al actualizar el monto de la transacción': 'Error al actualizar el monto de la transacción'
    };

    return errorMessages[message] || 'Error al procesar la solicitud';
  };

  const handleDelete = async () => {
    if (!trackingNumber.trim()) {
      message.error('Por favor ingrese un número de guía');
      return;
    }

    const requestBody = {
      guia: trackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: carrier
      }
    };

    Modal.confirm({
      title: 'Confirmación',
      content: '¿Estás seguro de que deseas desactivar esta guía?',
      okText: 'Sí, desactivar',
      cancelText: 'Cancelar',
      onOk: async () => {
        setLoading(true);
        try {
          const usuario_id = localStorage.getItem('usuario_id');
          const token = localStorage.getItem('token');

          const response = await fetch(`https://99envios.app/api/online/desactivar_preenvio/${usuario_id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(requestBody)
          });

          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.message || 'Error en la búsqueda de la guía');
          }

          if (data.message === "Preenvío desactivado y monto de la transacción actualizado a cero correctamente" ||
              data.message === "La transacción más reciente ha sido actualizada con éxito.") {
            message.success(data.message);
            setTrackingNumber('');
          } else {
            const errorMsg = handleErrorMessage(data.message);
            message.error(errorMsg);
          }
        } catch (error) {
          const errorMsg = handleErrorMessage(error.message);
          message.error(errorMsg);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  return (
    <>
      <NavbarAdminMex title="Desactivar guía"/>
      <div className="eliminar-guia-tracking-container-container">
        <div className="eliminar-guia-tracking-container">
          <h2>DESACTIVAR GUÍA</h2>
          <p>Coloca el número de guía que deseas desactivar</p>

          <div className="eliminar-guia-search-bar">
            <input
              type="text"
              value={trackingNumber}
              onChange={handleInputChange}
              placeholder="Ingrese guía"
              className="eliminar-guia-search-input"
            />
            <select 
              value={carrier} 
              onChange={handleCarrierChange} 
              className="eliminar-guia-carrier-select"
            >
              <option value="interrapidisimo">Interrapidisimo</option>
              <option value="servientrega">Servientrega</option>
              <option value="coordinadora">Coordinadora</option>
              <option value="tcc">TCC</option>
            </select>
          </div>
          <div className="eliminar-guia-search-bar">
            <button
              onClick={handleDelete}
              disabled={loading}
              className="eliminar-guia-search-button"
            >
              {loading ? 'Procesando...' : 'Desactivar'}
            </button>
          </div>
        </div>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default DesactivarGuia;