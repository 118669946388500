import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Row, Modal } from 'antd';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import BotonWhatsapp from "../../newInterface/botonWhatsapp/botonWhatsapp";
import "./pruebas.css";

const ReporteRepartidores = () => {
    const [reporte, setReporte] = useState([]);
    const [tipoFecha, setTipoFecha] = useState("hoy");
    const [fechaDesde, setFechaDesde] = useState("");
    const [fechaHasta, setFechaHasta] = useState("");
    const [detallePedido, setDetallePedido] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        obtenerReporteRepartidores();
    }, [tipoFecha, fechaDesde, fechaHasta]);


    const obtenerReporteRepartidores = async () => {
        try {
            const id_usuario = localStorage.getItem('usuario_id');
            const response = await axios.get(`https://api.99envios.app/api/reporte-repartidores/${id_usuario}`,
                {
                    params: {
                        tipo_fecha: tipoFecha,
                        fecha_desde: tipoFecha === "personalizado" ? fechaDesde : null,
                        fecha_hasta: tipoFecha === "personalizado" ? fechaHasta : null,
                    },
                });
            setReporte(response.data);
        } catch (error) {
            console.error("Error al obtener el reporte de repartidores:", error);
        }
    };


    const filteredReporte = tipoFecha === "personalizado"
        ? reporte.filter(item => item.fecha_pedido >= fechaDesde && item.fecha_pedido <= fechaHasta)
        : reporte;


    const handleCellClick = (idRepartidor, estadoPedido) => {
        obtenerDetallePedido(idRepartidor, estadoPedido);
    };


    const obtenerDetallePedido = async (repartidorId, estadoPedido) => {
        try {
            // Construye la URL con los valores dinámicos
            console.log(repartidorId, estadoPedido);
            const url = `https://api.99envios.app/api/filtrar-pedidos/${repartidorId}/${estadoPedido}`;

            const response = await axios.get(url);


            setDetallePedido(response.data);
            setIsModalVisible(true); // Para mostrar el modal con los detalles.
        } catch (error) {
            console.error("Error al obtener el detalle del pedido:", error);
            // Si hay un error, puedes optar por cerrar el modal o mostrar algún mensaje dentro de él.
            setIsModalVisible(false);
        }
    };

    // Coloca esto dentro del componente ReporteRepartidores donde tienes la lógica
    const totalPedidos = filteredReporte.reduce((acc, repartidor) => acc + (repartidor.total_pedidos ? parseInt(repartidor.total_pedidos) : 0), 0);


    const renderModalContent = () => {
        if (!detallePedido) return <p>Cargando...</p>;

        return (
            <>
                <h3>Pedidos Filtrados</h3>
                {detallePedido.length > 0 ? (
                    <div style={{ overflowX: 'auto' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#f2f2f2' }}>
                                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Nombre del cliente</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Fecha del pedido</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Estado del pedido</th>
                                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detallePedido.map((pedido, index) => (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{pedido.nombre_cliente}</td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{pedido.fecha_pedido}</td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{pedido.estado_pedido}</td>
                                        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{pedido.total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p>No se encontraron pedidos con los criterios especificados.</p>
                )}
            </>
        );
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        obtenerReporteRepartidores();
    };

    // Función para obtener el texto del rango de fechas
    const getFechaTexto = () => {
        if (tipoFecha === "personalizado" && fechaDesde && fechaHasta) {
            return `Resultado de ${fechaDesde} al ${fechaHasta}`;
        } else {
            return "";
        }
    };

    return (
        <div>
            <Navbar title={"Reporte repartidores"} />
            <div className="reporte-repartidores">
                <h2>Reporte de Repartidores</h2>
                <form onSubmit={handleSubmit}>
                    <Row gutter={16} align="middle">
                        <Col span={6}>
                            <div className="form-group">
                                <label>Rango de fecha</label>
                                <select
                                    value={tipoFecha}
                                    onChange={(e) => setTipoFecha(e.target.value)}
                                >
                                    <option value="hoy">Todos</option>
                                    <option value="personalizado">Personalizado</option>
                                </select>
                            </div>
                        </Col>
                        <Col span={12}>
                            {tipoFecha === "personalizado" && (
                                <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: '8px' }}>
                                        <label>Fecha Desde:</label>
                                        <input
                                            type="date"
                                            value={fechaDesde}
                                            onChange={(e) => setFechaDesde(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label>Fecha Hasta:</label>
                                        <input
                                            type="date"
                                            value={fechaHasta}
                                            onChange={(e) => setFechaHasta(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <a href="/crear/conductor" className="btn-generar-reporte">Ingresa nuevo conductor</a>
                    </Col>
                </form>
                {/* Mostrar el rango de fechas */}
                <p>{getFechaTexto()}</p>

                <table className="table-reporte">
                    <thead>
                        <tr>
                            <th>Repartidor</th>
                            <th>Pedidos</th>
                            <th>Entregados</th>
                            <th>Devuelto</th>
                            <th>En Proceso</th>
                            <th>En Espera</th>
                            <th>Pago Digital</th>
                            <th>Contra Reembolso - Efectivo</th>
                            <th>Total Recaudo</th>
                            <th>Costo Envio</th>
                            <th>FEE</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>



                        {filteredReporte.map((repartidor, index) => (
                            <tr key={index}>
                                <td>{repartidor.nombre_repartidor ? repartidor.nombre_repartidor : "Sin asignar"}</td>
                                <td >
                                    {repartidor.total_pedidos ? repartidor.total_pedidos : "Sin asignar"}
                                </td>
                                <td onClick={() => handleCellClick(repartidor.id_repartidor, 'Entregado')} style={{ cursor: 'pointer', color: '#6C63FF' }}>
                                    {repartidor.entregados}
                                </td>
                                <td onClick={() => handleCellClick(repartidor.id_repartidor, 'Devuelto')} style={{ cursor: 'pointer', color: '#6C63FF' }}>
                                    {repartidor.devueltos}
                                </td>
                                <td onClick={() => handleCellClick(repartidor.id_repartidor, 'En proceso')} style={{ cursor: 'pointer', color: '#6C63FF' }}>
                                    {repartidor.en_proceso}
                                </td>
                                <td onClick={() => handleCellClick(repartidor.id_repartidor, 'En espera')} style={{ cursor: 'pointer', color: '#6C63FF' }}>
                                    {repartidor.en_espera}
                                </td>
                                <td style={{ cursor: 'pointer', }}>
                                    {repartidor.pago_digital ? repartidor.pago_digital : 0}
                                </td>
                                <td style={{ cursor: 'pointer', }}>
                                    {repartidor.contra_entrega ? repartidor.contra_entrega : 0}
                                </td>
                                <td style={{ cursor: 'pointer', }}>
                                    {repartidor.total_pagos ? repartidor.total_pagos : 0}
                                </td>
                                <td style={{ cursor: 'pointer', }}>
                                    {repartidor.costo_envio ? repartidor.costo_envio : 0}
                                </td>

                                <td style={{ cursor: 'pointer' }} >
                                    {repartidor.fee99 ? repartidor.fee99 : 0}
                                </td>


                                <td>
                                    {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(repartidor.total)}
                                </td>


                                {/* ... (otros datos de la tabla si los hay) */}
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="12" style={{ textAlign: 'left', fontWeight: 'bold' }}>Total de Pedidos:  {totalPedidos}</td>
                        </tr>


                    </tbody>
                </table>
            </div>
            <Modal
                title="Detalle del Pedido"
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
            >
                {renderModalContent()}
            </Modal>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
};

export default ReporteRepartidores;
