import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Card } from 'antd';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './FormularioBodega.css';

const { Option } = Select;

const FormularioBodega = () => {
    const [form] = Form.useForm();
    const [bodegas, setBodegas] = useState([]);

    useEffect(() => {
        const id_user = localStorage.getItem('usuario_id');

        // Fetch the list of warehouses
        fetch(`https://99envios.app/api/picking/bodega`)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setBodegas(data);
                } else {
                    console.error('Fetched data is not an array:', data);
                    setBodegas([]);
                }
            })
            .catch(error => {
                console.error('Error fetching bodegas:', error);
                setBodegas([]);
            });
    }, [form]);

    const onFinish = (values) => {
        const id_user = localStorage.getItem('usuario_id');
        fetch(`https://99envios.app/api/picking/bodega/asignar-bodega/${id_user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_bodega: values.bodega }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Response from API:', data);
        })
        .catch(error => {
            console.error('Error sending data to API:', error);
        });
    };

    return (
        <>
            <NavbarPicking title="Selección de Bodega" />
            <div className="formulario-bodega-container">
                <Card title="Selecciona una Bodega" className="formulario-bodega-card">
                    <Form
                        form={form}
                        name="bodegaForm"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Selecciona Bodega:"
                            name="bodega"
                            rules={[{ required: true, message: 'Por favor selecciona una bodega' }]}
                        >
                            <Select placeholder="Selecciona una bodega">
                                {bodegas.map(bodega => (
                                    <Option key={bodega.id} value={bodega.id}>
                                        {bodega.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button style={{ height: "44px" }} type="primary" htmlType="submit" className="formulario-bodega-submit-button">
                                Confirmar Selección
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    );
};

export default FormularioBodega;
