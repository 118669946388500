import React, { useEffect, useState, useCallback  } from 'react';
import { useNavigate } from 'react-router-dom';
import './Estilos/EsperaStyle.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import Carga from '../../../components/carga';
import axios from 'axios';
import BotonWhatsapp from '../../../newInterface/botonWhatsapp/botonWhatsapp';

function Espera() {
    const [orders, setOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [ordersPerPage] = useState(10);

    let navigate = useNavigate();

    const fetchOrders = useCallback(async () => {
        setIsLoading(true);
        const usuario_id = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/reporte-pedidos-espera/${usuario_id}`;
        try {
            const response = await axios.get(url);
            setOrders(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);





    // Calcula los índices de los pedidos que deben mostrarse en la página actual.
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

    // Cambia la página actual al número de página seleccionado.
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // Genera los números de las páginas para la paginación.
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(orders.length / ordersPerPage); i++) {
        pageNumbers.push(i);
    }
    function handleDetailsClick(orderId) {
        navigate(`/conductor/pedidos/${orderId}`);
    }

    

    return (
        <div>
            <Navbar title={"En espera"} />

            <div className="app-container">
                <Head title={"En espera"} />
                <p className="instructions">
                    <b>Marcar pedidos como listos para entrega y salir a entregar</b>
                    <br />
                    Elija pedidos y haga clic
                </p>
                {isLoading ? (
                    <div className="loading-container">
                        <Carga />
                    </div>
                ) : (
                    <div className="orden">
                        <div className="orders-list">
                            {currentOrders.map((order) => (
                                <div key={order.ID_pedido} className="order-card">
                                    <div className="order-details">
                                        <h2>Pedido #{order.ID_pedido}</h2>
                                        <p>{order.nombre_cliente}</p>
                                        <p>{order.direccion}</p>
                                        <p>{order.fecha_pedido}</p>
                                        <p>{order.estado_pedido}</p>
                                    </div>
                                    <button className="details-button" onClick={() => handleDetailsClick(order.ID_pedido)}>Detalles del pedido</button>
                                    {/* Mover el botón Salir a entregar aquí dentro del mapeo */}
                                    <br />
                                    {/* <button className="deliver-button" onClick={() => handleChangeStatus (order.ID_pedido)}>
                                        <FontAwesomeIcon icon={faTruck} />
                                        Salir a entregar
                                    </button> */}
                                </div>
                                
                            ))}
                        </div>
                        
                        <div className="pagination">
                            
                            {pageNumbers.map(number => (
                                <button key={number} onClick={() => paginate(number)} className={currentPage === number ? 'active' : ''}>
                                    {number}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <BotonWhatsapp />
            <Footer />
        </div>
    );
};

export default Espera;