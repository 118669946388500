import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, message } from 'antd';
import { GoogleMap, DirectionsRenderer, useLoadScript, Autocomplete, DirectionsService } from '@react-google-maps/api';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import './maps.css';

const mapContainerStyle = {
    height: '100vh',
    width: '100%'
};

const center = {
    lat: 4.7110,
    lng: -74.0721
};

const librariesMaps = ['places'];

const AnyReactComponent = ({ text, onMarkerDragEnd }) => (
    <div draggable onDragEnd={onMarkerDragEnd} style={{ cursor: 'pointer' }}>
        {text}
    </div>
);

const Visitas = () => {
    const id = localStorage.getItem('usuario_id')
    // mapa autocomplete
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAbErc-9C5rqyrGudtyKlk7LJOfqhbu9c0',
        libraries: librariesMaps,
    });

    const [autocompletes, setAutocompletes] = useState({});
    const [autocomplete, setAutocomplete] = useState(null);
    const [destinations, setDestinations] = useState([{ address: '', location: { lat: null, lng: null } }]);
    const [cantidad, setCantidad] = useState(0);
    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [estado, setEstado] = useState('');
    const [telefono, setTelefono] = useState('');
    const [fecha_de_entrega, setFechaDeEntrega] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const originRef = useRef(null);
    const destinationRef = useRef(null)
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [productType, setProductType] = useState('');
    const [productos, setProductos] = useState([]);
    const [selectedProducto, setSelectedProducto] = useState(null);
    const [email, setEmail] = useState('');
    const [costoEnvio, setCostoEnvio] = useState('');
    const [repartidores, setRepartidores] = useState([]);
    const [selectedRepartidorId, setSelectedRepartidorId] = useState('');

    // Nuevo Modal producto
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [newProductData, setNewProductData] = useState({
        nombre_producto: '',
        id_sucursal: id,
        id_categoria: '',
        cantidad_disponible: 0,
        peso: '',
        color: '',
        talla: '',
        fotoproducto: null,
        descripcion_producto: '',
        informacion_adicional: 'Información adicional',
        costo: ''
    });
    const [productosSeleccionados, setProductosSeleccionados] = useState([]);
    const [touched, setTouched] = useState({
        newProductName: false,
        newQuantity: false,
        newWeight: false,
        newValue: false
    });

    const handleBlur = (field) => {
        setTouched({ ...touched, [field]: true });
    };

    const inputStyle = (field) => ({
        borderColor: touched[field] && !eval(field) ? 'red' : '#d9d9d9'
    });

    // Envio pedido a siguiente vista
    const navigate = useNavigate();

    const showNewProductModal = () => {
        setIsNewProductModalVisible(true);
    };

   

    // Manejar cambio de campos de nuevo producto
    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProductData({
            ...newProductData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        setNewProductData({
            ...newProductData,
            [name]: e.target.files[0]
        });
    };

    // Manejar OK en el modal de creación
    const handleNewProductSave = () => {
        setIsNewProductModalVisible(false);

        const formData = new FormData();
        for (const key in newProductData) {
            formData.append(key, newProductData[key]);
        }

        console.log("Enviando data del nuevo producto:", formData);

        const url = `https://api.99envios.app/api/inventarios/${id}`;

        fetch(url, {
            method: 'POST',
            body: formData, // Enviar el FormData
        })
            .then(response => response.json())
            .then(data => {
                message.success('Nuevo producto creado con éxito');
                setTimeout(() => {
                    window.location.reload(true); // Recargar la página sin caché después de 3 segundos
                }, 2000);
            })
            .catch((error) => {
                console.error('Error:', error);
                message.error('Error al crear el producto');
            });
    };

    // modal viejo
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        console.log('Producto seleccionado:', selectedProducto, 'Cantidad:', cantidad);

        setIsModalVisible(false);
    };

    // Repartidores
    useEffect(() => {
        const fetchRepartidores = async () => {
            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/conductores-sucursal/${id}`;
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const repartidoresList = await response.json();
                setRepartidores(repartidoresList);
            } catch (error) {
                console.error('Error fetching repartidores:', error);
            }
        };

        fetchRepartidores();
    }, []);

    // Destinos
    useEffect(() => {
        if (isLoaded && window.google) {
            setAutocomplete(new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {}));
        }
    }, [isLoaded]);

    const onSaveOrder = async () => {
        const ordersData = productosSeleccionados.map(producto => ({
            nombre: nombre,
            origin: origin,
            email: email,
            direccion: destination,
            estado: "Espera",
            telefono: telefono,
            costo_envio: parseInt(costoEnvio, 10),
            fecha_de_entrega: fecha_de_entrega,
            id_repartidor: parseInt(selectedRepartidorId, 10),
            id_producto: parseInt(producto.id, 10),
            cantidad: parseInt(producto.cantidad, 10)
        }));

        const usuario_id = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/generar-pedido/${usuario_id}`;
        let successfulOrders = [];

        for (const orderData of ordersData) {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                successfulOrders.push(data.pedido_id);
            } catch (error) {
                console.error('Error al guardar el pedido:', error);
                message.error('Error al guardar el pedido');
            }
        }

        if (successfulOrders.length > 0) {
            message.success(`Pedidos guardados con éxito con IDs: ${successfulOrders.join(', ')}`);
            navigate('/pedido/rutas', { state: { pedidoIds: successfulOrders } });
        } else {
            message.error('No se pudieron guardar los pedidos.');
        }
    };

    useEffect(() => {
        const fetchProduct = async () => {
            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/inventarios/${id}`;
            console.log(url);
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const productList = await response.json();
                const formattedProducts = productList.map(product => ({
                    id: product.id_producto,
                    name: product.nombre_producto
                }));
                setProductos(formattedProducts);
                console.log('Productos:', formattedProducts);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProduct();
    }, []);

    if (!isLoaded) {
        return <div>Cargando...</div>;
    }
    if (loadError) {
        return <div>Error al cargar la API de Google Maps</div>;
    }

    const calculateRoute = async () => {
        if (!origin || !destination) {
            console.error("Se requieren tanto un origen como un destino para calcular una ruta.");
            return;
        }
        try {
            const directionsService = new window.google.maps.DirectionsService();
            const results = await directionsService.route({
                origin: origin,
                destination: destination,
                optimizeWaypoints: true,
                travelMode: window.google.maps.TravelMode.DRIVING,
            });
            setDirectionsResponse(results);
            console.log("Waypoint Order:", results.routes[0].waypoint_order);
        } catch (error) {
            console.error("Error fetching directions", error);
        }
    };

    const now = new Date();
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const currentDate = now.toLocaleDateString('es-ES', options);

    const mapZoom = 15;

    const products = [
        { name: '1. Bisuteria' },
        { name: '2. Ropa Deportiva' },
        { name: '3. Vaporizadores' },
        { name: '4. Mascotas' },
        { name: '5. Moda' },
        { name: '6. Tecnologia' },
        { name: '7. Cocina' },
        { name: '8. Belleza' },
        { name: '9. Salud' },
        { name: '10. Hogar' },
        { name: '11. Natural Home' },
        { name: '12. Deportes' },
        { name: '13. Sex Shop' },
        { name: '14. Bebé' },
        { name: '15. Aseo' },
        { name: '16. Bienestar' },
        { name: '17. Camping' },
        { name: '18. Pesca' },
        { name: '19. Defensa Personal' },
        { name: '20. Vehiculos' },
        { name: '21. Jugueteria' },
        { name: '22. Otros' },
    ];

    const agregarProducto = () => {
        setProductosSeleccionados([...productosSeleccionados, { id: '', cantidad: 0 }]);
    };

    const actualizarProducto = (index, value) => {
        const nuevosProductos = [...productosSeleccionados];
        nuevosProductos[index].id = value;
        setProductosSeleccionados(nuevosProductos);
    };

    const actualizarCantidad = (index, value) => {
        const nuevosProductos = [...productosSeleccionados];
        nuevosProductos[index].cantidad = Number(value);
        setProductosSeleccionados(nuevosProductos);
    };

    const onLoadOrigin = (autocomplete) => {
        originRef.current = autocomplete;
    };

    const onLoadDestination = (autocomplete) => {
        destinationRef.current = autocomplete;
    };

    const onPlaceChangedOrigin = () => {
        if (originRef.current && originRef.current.getPlace()) {
            const place = originRef.current.getPlace();
            setOrigin(place.formatted_address);
        }
    };

    const onPlaceChangedDestination = () => {
        if (destinationRef.current && destinationRef.current.getPlace()) {
            const place = destinationRef.current.getPlace();
            setDestination(place.formatted_address);
        }
    };

    const handleCanceel = () => {
        setIsModalVisible(false);
        setIsNewProductModalVisible(false);
    };

    return (
        <>
            <Navbar title={"Crear pedido individual"} />
            <div className="containerMap">
                <div className="navbarMap">
                    <div className="navbar-date">{currentDate}</div>
                </div>
                <div className="sidebarMap">
                    <h2> Crear pedido</h2>
                    <form className="edit-order-form">
                        <button type="button" style={{ backgroundColor: 'green' }} onClick={showNewProductModal}>Crear Nuevo Producto</button>
                        <button type="button" style={{ backgroundColor: 'blue', color: 'black' }} className='btnp' onClick={showModal}>Seleccionar producto</button>
                        <div className="Partida">
                            <label>Punto de partida</label>
                            {isLoaded && (
                                <Autocomplete onLoad={onLoadOrigin} onPlaceChanged={onPlaceChangedOrigin}>
                                    <input
                                        type="text"
                                        value={origin}
                                        onChange={(e) => {
                                            setOrigin(e.target.value);
                                        }}
                                        placeholder="Inicio"
                                    />
                                </Autocomplete>
                            )}
                        </div>
                        <div className='Destino'>
                            <label>DESTINO</label>
                            {isLoaded && (
                                <Autocomplete onLoad={onLoadDestination} onPlaceChanged={onPlaceChangedDestination}>
                                    <input type="text" value={destination}
                                        onChange={(e) => {
                                            setDestination(e.target.value);
                                        }} placeholder="Destino" />
                                </Autocomplete>
                            )}
                        </div>

                        <button type="button" onClick={calculateRoute} >Calcular ruta</button>

                        <label>FECHA DE ENTREGA</label>
                        <input
                            type="date" placeholder="Fecha de entrega"
                            onChange={(e) => setFechaDeEntrega(e.target.value)}
                        />

                        <label>VENTANA DE TIEMPO</label>
                        <div className="time-window">
                            <input type="time" placeholder="00:00" />
                            <span>HASTA</span>
                            <input type="time" placeholder="23:59" />
                        </div>

                        <label>PERSONA QUE RECIBE</label>
                        <input
                            type="text"
                            placeholder="Nombre del contacto"
                            onChange={(e) => setNombre(e.target.value)}
                        />

                        <label>TELÉFONO DE QUIEN RECIBE</label>
                        <input
                            type="tel"
                            placeholder="Número de teléfono"
                            onChange={(e) => setTelefono(e.target.value)}
                        />

                        <label>CORREO ELECTRÓNICO DE QUIEN RECIBE</label>
                        <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Añade un correo electrónico" />

                        <label>Repartidor</label>
                        <select onChange={(e) => setSelectedRepartidorId(e.target.value)}>
                            <option value="">Seleccione un repartidor</option>
                            <option value="">Sin asignar</option>
                            {repartidores.map(repartidor => (
                                <option key={repartidor.id_repartidor} value={repartidor.id_repartidor}>
                                    {repartidor.nombre}
                                </option>
                            ))}
                        </select>
                        <label>Comisión del Repartidor</label>
                        <input type="number" placeholder="Comisión" onChange={(e) => setCostoEnvio(e.target.value)} />

                        <label>Nota al conductor</label>
                        <textarea placeholder="Agrega una nota para el conductor"></textarea>

                        <Modal
                            title="Guardar producto"
                            visible={isModalVisible}
                            onOk={handleOk}
                            onCancel={() => setIsModalVisible(false)}
                            className="ant-modal-custom"
                        >
                            <div className="ant-modal-body">
                                {productosSeleccionados.map((producto, index) => (
                                    <div key={index}>
                                        <select
                                            className="ant-select"
                                            value={producto.id}
                                            onChange={(e) => actualizarProducto(index, e.target.value)}
                                        >
                                            <option value="">Seleccione un producto</option>
                                            {productos.map(p => (
                                                <option key={p.id} value={p.id}>
                                                    Id: {p.id} - {p.name}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            className="ant-input"
                                            type="number"
                                            value={producto.cantidad}
                                            onChange={(e) => actualizarCantidad(index, e.target.value)}
                                            placeholder="Cantidad"
                                        />
                                    </div>
                                ))}
                                <button type="button" onClick={agregarProducto}>Añadir más productos</button>
                            </div>
                        </Modal>

                        <Modal
                            title="Crear nuevo producto"
                            visible={isNewProductModalVisible}
                            onOk={handleNewProductSave}
                            onCancel={handleCanceel}
                            className="ant-modal-custom"
                        >
                            <div className="ant-modal-body">
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Categoría del producto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <select className="ant-select" name="id_categoria" onChange={handleNewProductChange} value={newProductData.id_categoria}>
                                            <option value="">Escoge una categoría</option>
                                            <option value="1">Bisuteria</option>
                                            <option value="2">Ropa Deportiva</option>
                                            <option value="3">Vaporizadores</option>
                                            <option value="4">Mascotas</option>
                                            <option value="5">Moda</option>
                                            <option value="6">Tecnologia</option>
                                            <option value="7">Cocina</option>
                                            <option value="8">Belleza</option>
                                            <option value="9">Salud</option>
                                            <option value="10">Hogar</option>
                                            <option value="11">Natural Home</option>
                                            <option value="12">Deportes</option>
                                            <option value="13">Sex Shop</option>
                                            <option value="14">Bebé</option>
                                            <option value="15">Aseo</option>
                                            <option value="16">Bienestar</option>
                                            <option value="17">Camping</option>
                                            <option value="18">Pesca</option>
                                            <option value="19">Defensa Personal</option>
                                            <option value="20">Vehiculos</option>
                                            <option value="21">Jugueteria</option>
                                            <option value="22">Otros</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Nombre del producto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="text" name="nombre_producto" onChange={handleNewProductChange} placeholder="Nombre del nuevo producto" />
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Descripción del producto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="text" name="descripcion_producto" onChange={handleNewProductChange} placeholder="Descripción" />
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Cantidad disponible para enviar</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="number" name="cantidad_disponible" onChange={handleNewProductChange} placeholder="Cantidad" />
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Peso del producto por unidad</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="text" name="peso" onChange={handleNewProductChange} placeholder="Peso" />
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Color del producto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <select className="ant-input" name="color" onChange={handleNewProductChange} value={newProductData.color}>
                                            <option value="">Selecciona un color</option>
                                            <option value="rojo">Rojo</option>
                                            <option value="azul">Azul</option>
                                            <option value="verde">Verde</option>
                                            <option value="amarillo">Amarillo</option>
                                            <option value="negro">Negro</option>
                                            <option value="No">No aplica</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Talla del producto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="text" name="talla" onChange={handleNewProductChange} placeholder="Talla" />
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label htmlFor="fileUpload">Foto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="file" name="fotoproducto" id="fileUpload" onChange={handleFileChange} />
                                    </div>
                                </div>
                                <div className="ant-row ant-form-item">
                                    <div className="ant-col ant-form-item-label">
                                        <label>Valor individual del producto</label>
                                    </div>
                                    <div className="ant-col ant-form-item-control">
                                        <input className="ant-input" type="text" name="costo" onChange={handleNewProductChange} placeholder="Valor" />
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <button type="button" onClick={onSaveOrder}>Guardar pedido</button>
                    </form>
                </div>
                <div className="map">
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={15}
                    >
                        {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>
                </div>
            </div>
            <BotonWhatsapp />
            <Footer />
        </>
    );
};

export default Visitas;
