import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Button, Space, Form, Popconfirm, InputNumber } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';

import * as FileSaver from 'file-saver';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Por favor ingrese ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const exportToCSV = (data) => {
    const header = Object.keys(data[0]).join(',') + '\n'; // Crea los encabezados
    const csvRows = data.map(row => Object.values(row).join(',')).join('\n');
    const blob = new Blob([header + csvRows], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'pedidos.csv');
}

const PedidosTablaEdit = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();
    const searchInput = useRef();

    useEffect(() => {
        fetchPedidos();
    }, []);

    const fetchPedidos = async () => {
        try {
            const id_usuario = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/reporte-pedidos/${id_usuario}`;
            const response = await axios.get(url);
            console.log('Response:', response.data);
            setPedidos(response.data.pedidos);
        } catch (error) {
            console.error('Error al obtener los pedidos:', error);
        }
    };

    const isEditing = (record) => record.ID_pedido === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ID_pedido: '',
            nombre_cliente: '',
            fecha_pedido: '',
            estado_pedido: '',
            nombre_repartidor: '',
            total: '',
            ...record,
        });
        setEditingKey(record.ID_pedido);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (ID_pedido) => {
        try {
            const row = await form.validateFields();
            const newData = [...pedidos];
            const index = newData.findIndex((item) => item.ID_pedido === ID_pedido);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setPedidos(newData);
                setEditingKey('');
                // Actualizar en el servidor
                await axios.put(`https://api.99envios.app/api/pedidos/${ID_pedido}`, row);
            } else {
                newData.push(row);
                setPedidos(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const handleDelete = async (ID_pedido) => {
        const newData = pedidos.filter((item) => item.ID_pedido !== ID_pedido);
        setPedidos(newData);
        // Eliminar en el servidor
        await axios.delete(`https://api.99envios.app/api/pedidos/${ID_pedido}`);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reiniciar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'ID Pedido',
            dataIndex: 'ID_pedido',
            key: 'ID_pedido',
            ...getColumnSearchProps('ID_pedido'),
            editable: true,
        },
        {
            title: 'Pedido',
            dataIndex: 'nombre_cliente',
            key: 'nombre_cliente',
            ...getColumnSearchProps('nombre_cliente'),
            editable: true,
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha_pedido',
            key: 'fecha_pedido',
            ...getColumnSearchProps('fecha_pedido'),
            editable: true,
        },
        {
            title: 'Estado',
            dataIndex: 'estado_pedido',
            key: 'estado_pedido',
            ...getColumnSearchProps('estado_pedido'),
            editable: true,
        },
        {
            title: 'Repartidor',
            dataIndex: 'nombre_repartidor',
            key: 'nombre_repartidor',
            ...getColumnSearchProps('nombre_repartidor'),
            editable: true,
        },
        {
            title: 'Total Pedido',
            dataIndex: 'total',
            key: 'total',
            ...getColumnSearchProps('total'),
            editable: true,
            render: (text) => {
                const total = parseFloat(text);
                const formattedTotal = new Intl.NumberFormat('es-CO', {
                    style: 'currency',
                    currency: 'COP',
                    minimumFractionDigits: 2,
                }).format(total);
                return formattedTotal;
            },
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            onClick={() => save(record.ID_pedido)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Guardar
                        </Button>
                        <Popconfirm title="¿Seguro de cancelar?" onConfirm={cancel}>
                            <Button>Cancelar</Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space size="middle">
                        <Button
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                            icon={<EditOutlined />}
                        >
                            Editar
                        </Button>
                        <Popconfirm title="¿Seguro de eliminar?" onConfirm={() => handleDelete(record.ID_pedido)}>
                            <Button icon={<DeleteOutlined />}>Eliminar</Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'total' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <>
            <Navbar title={"Tabla de edición de pedidos"} pedidos={"Pedidos"} crearPedido={"Crear Pedidos"} crearConductor={"Crear conductor"} reportes={"Reportes"} />
            <div className="pedidos-tabla">
                
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={pedidos}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{ pageSize: 10 }}
                    />
                </Form>
            </div>
            <BotonWhatsapp />
            <Footer />
        </>
    );
};

export default PedidosTablaEdit;
